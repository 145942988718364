.accordion {
    &__title {
        display: block;
        padding: 25px 0 45px 28px;
        z-index: 2;
        border-radius: 5px;
        box-shadow: 0 3px 3px rgba(0, 0, 0, .15);
        transition: background-color $trans-med ease;
        @include medium {
            padding: 32px 28px 32px 40px;
        }
        @include xlarge {
            padding: 32px 32px 32px 50px;
        }
        span {
            font-size: 1.875rem;
            font-weight: $font-weight-bold;
            display: inline-block;
            color: $primary-color-dark;
            max-width: 70%;
            line-height: 1.125;
            transition: color $trans-med ease;
        }
        &::before, &::after {
            content: '';
            background-color: $primary-color-dark;
            height: 27px;
            width: 4px;
            display: block;
            position: absolute;
            border-radius: 6px;
            right: 28px;
            top: 24px;
            transition: height $trans-med ease, top $trans-med ease, transform $trans-med ease;
            @include medium {
                height: 32px;
                top: 32px;
                right: 36px;
            }
            @include xlarge {
                right: 40px;
            }
        }
        &::after {
            transform: rotate(90deg);
        }
    }
    &__inner-container {
        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        padding: 0 16px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        transition: max-height $trans-slow ease, padding $trans-slow ease, visibility $trans-slow ease, box-shadow $trans-slow ease;
        @include medium {
            padding: 0 20px;
            transition: max-height $trans-med ease, padding $trans-med ease, visibility $trans-med ease, box-shadow $trans-med ease;
        }
        @include xlarge {
            padding: 0 90px;
        }
    }
    &.active {
        .accordion__title {
            background-color: $primary-color-dark;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            span {
                color: $white;
            }
            &::before {
                transform: rotate(45deg);
                background-color: $white;
            }
            &::after {
                transform: rotate(135deg);
                background-color: $white;
            }
        }
        .accordion__inner-container {
            max-height: 5000px;
            visibility: visible;
            padding: 36px 16px;
            box-shadow: 0 6px 20px rgba(106, 106, 106, 0.39);
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            transition: max-height $trans-med ease, padding $trans-med ease, visibility $trans-med ease, box-shadow $trans-med ease;
            @include medium {
                padding: 52px 20px;
            }
            @include xlarge {
                padding: 52px 90px;
            }
        }
    }
}


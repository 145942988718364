.events-list {
    padding: 0 12px 30px;
    @include medium {
        padding: 0 40px 40px;
    }
    @include xlarge {
        padding: 0 80px 45px;
    }
}
.load-events-btn {
    width: 319px;
    max-width: 92%;
    margin: 0 auto 32px;
}
.event {
    display: flex;
    padding: 20px 16px;
    border: 2px solid $brown;
    border-radius: 5px;
    margin: 0 auto 16px;
    max-width: 450px;
    @include medium {
        padding: 24px 24px 30px;
        margin-bottom: 38px;
        max-width: 930px;
    }
    @include xxlarge {
        padding: 24px;
        max-width: 1520px;
        margin-bottom: 24px;
    }
    &__event-date {
        margin-right: 22px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include medium {
            margin-right: 45px;
        }
        @include xxlarge {
            padding: 20px 20px 20px 30px;
        }
    }
    &__event-month {
        font-size: 1.25rem;
        font-family: garamond-atf-subhead, serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1.45;
        color: #1c1c1c;
        margin-bottom: 5px;
        @include medium {
            font-size: 2.125rem;
            letter-spacing: 3.4px;
        }
    }
    &__event-day {
        font-size: 5.1875rem;
        font-weight: $font-weight-med;
        font-style: italic;
        font-family: garamond-atf-subhead, serif;
        color: $grape-purple;
        line-height: .7;
        @include medium {
            font-size: 8.0625rem;
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        @include medium {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }
    }
    &__event-title {
        font-size: 1.375rem;
        line-height: 1.27;
        margin-bottom: 12px;
        color: $charcoal-grey;
        @include medium {
            font-size: 1.5rem;
        }
        @include xxlarge {
            font-size: 2.125rem;
            margin-bottom: 16px;
            padding-top: 12px;
        }
        &::before {
            @include xxlarge {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 67px;
                height: 2px;
                background-color: $brown;
            }
        }
    }
    &__event-info {
        @include medium {
            width: calc(100% - 220px);
        }
        @include xxlarge {
            width: calc(100% - 430px);
            padding: 20px 0;
        }
        p {
            color: $charcoal-grey;
            font-size: .875rem;
            line-height: 1.43; 
            @include medium {
                font-size: 1rem;
            }
            @include xxlarge {
                font-size: 1.125rem;
                line-height: 1.5;
            }
        }
        p:empty {
            display: none;
        }
    }
    &__event-time {
        color: #3c3c3b;
        font-size: .875rem;
        line-height: 1.43;
        font-weight: $font-weight-bold;
        display: block;
        margin-bottom: 6px;
        @include medium {
            font-size: 1rem;
            display: inline-block;
            margin: 0;
        }
        @include xxlarge {
            font-size: 1.125rem;
        }
    }
    &__event-location {
        color: #3c3c3b;
        font-size: .875rem;
        line-height: 1.43;
        font-weight: $font-weight-bold;
        display: block;
        margin-bottom: 16px;
        @include medium {
            font-size: 1rem;
            display: inline-block;
        }
        @include xxlarge {
            font-size: 1.125rem;
            margin-bottom: 20px;
        }
    }
    &__event-divider {
        display: none;
        @include medium {
            margin: 0 10px;
            font-weight: $font-weight-bold;
            font-size: 1rem;
            display: inline-block;
        }
    }
    &__event-description {
        //
    }
    &__event-link {
        display: table;
        color: $cobalt-blue;
        font-size: 1.25rem;
        font-weight: $font-weight-med;
        margin-top: 16px;
        background-image: url('../images/arrow.svg');
        background-position: right center;
        background-repeat: no-repeat;
        padding-right: 26px;
        background-size: 18px;
        &:hover {
            text-decoration: underline;
        }
    }
    &__image {
        display: none;
        @include medium {
            display: flex;
            min-width: 179px;
            width: 179px;
            height: 179px;
            border-radius: 3px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 32px;
            background-color: $cobalt-blue;
        }
        @include xxlarge {
            min-width: 279px;
            width: 279px;
            height: 279px;
            margin-left: 100px;
        }
    }
}

.events-filter {
    padding: 0 12px;
    max-width: 1680px;
    margin: 0 auto;
    @include medium {
        padding: 0 40px;
    }
    @include xlarge {
        padding: 0 80px;
    }
    &__button {
        background-color: $cobalt-blue;
        color: $white;
        width: 150px;
        height: 46px;
        padding: 6px 29px;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: $font-weight-semi;
        font-size: 1.125rem;
        margin-bottom: 30px;
        @include medium {
            width: 163px;
            height: 50px;
            font-size: 1.25rem;
            margin-bottom: 36px;
        }
        svg {
            transition: transform $trans-med ease
        }
        &.active {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    &__filters {
        border: 1px solid #d6d4d3;
        border-radius: 3px;
        padding: 26px 26px 2px;
        display: flex !important;
        flex-direction: column;
        margin-bottom: 40px;
        max-height: 700px;
        transition: all $trans-med ease;
        @include medium {
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 76px;
            padding: 36px 36px 12px 46px;
            max-width: 750px;
        }
        @include xxlarge {
            margin-bottom: 88px;
            max-width: 900px;
        }
        > p {
            font-size: 1.125rem;
            font-weight: $font-weight-semi;
            margin-bottom: 24px;
            color: $night-blue;
            @include medium {
                flex-basis: 100%;
            }
        }
        > label {
            font-size: 1rem;
            font-weight: $font-weight-med;
            margin-bottom: 24px;
            cursor: pointer;
            @include medium {
                margin-right: 36px;
            }
        }
        &.hidden {
            max-height: 0;
            overflow: hidden;
            padding: 0 26px;
            margin-bottom: 0;
            border-color: transparent;
            @include medium {
                padding: 0 36px 0 46px;
            }
        }
    }
    + h3 {
        font-size: 1.375rem;
        font-family: museo-sans, sans-serif;
        font-weight: 900;
        letter-spacing: 3.3px;
        text-transform: uppercase;
        padding: 0 12px;
        max-width: 1680px;
        margin: 0 auto 28px;
        text-align: center;
        @include medium {
            text-align: left;
            padding: 0 40px;
            font-size: 1.75rem;
            margin-bottom: 38px;
            width: 100%;
        }
        @include xlarge {
            padding: 0 80px;
        }
        @include xxlarge {
            font-size: 2.125rem;
            margin-bottom: 30px;
        }
    }
}


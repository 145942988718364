header {
    &.admin {
        margin-top: 46px;
        @media (min-width: 783px) {
            margin-top: 32px;
        }
    }
}

.main-navigation {
    max-height: 0;
    position: absolute;
    width: 100%;
    z-index: 99;
    overflow: hidden;
    visibility: hidden;
    transition: max-height $trans-med ease, visibility $trans-med ease;
    @include xxlarge {
        width: auto;
        min-width: 67vw;
    }
    &.active {
        max-height: 5000px;
        visibility: visible;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        @include xxlarge {
            overflow: visible;
        }
    }
    &.third-level {
        @include xxlarge {
            width: 100%;
        }
    }
    .main-nav-outer-container {
        padding: 20px 0 28px;
        background-color: $cobalt-blue;
        @include medium {
            padding: 0;
            background-color: transparent;
        }
        @include xxlarge {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            min-height: 600px;
        }
        &.dark {
            background-color: $night-blue;
            @include medium {
                background-color: transparent;
            }
        }
        &.dark.light {
            background-color: $pale;
            @include medium {
                background-color: transparent;
            }
            .mobile-search-box {
                background-color: $white;
            }
        }
        &.active {
            padding-bottom: 0;
            .menu-main-navigation-container, .main-navigation__misc-links {
                display: none;
                @include medium {
                    display: block;
                }
            }
            .main-navigation__misc-links.hide {
                display: none;
                @include medium {
                    display: none;
                }
            }
        }
    }
    .main-navigation__intro {
        display: none;
        @include xxlarge {
            display: block;
            position: absolute;
            left: 34vw;
            width: 33vw;
            height: 100%;
            top: 0;
            background-color: $night-blue;
            padding: 72px 100px;
        }
        h3 {
            margin-bottom: 12px;
            font-size: 2.625rem;
            color: $white;
        }
        p {
            color: $white;
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 40px;
        }
        span {
            color: $white;
            font-size: 1rem;
            font-weight: $font-weight-med;
            svg {
                margin-right: 8px;
                transform: rotate(180deg);
                path {
                    stroke: $white;
                }
            }
        }
    }
    .menu-main-navigation-container {
        background-color: $cobalt-blue;
        padding: 14px 30px 28px;
        @include medium {
            padding: 34px 56px 33px;
        }
        @include xxlarge {
            padding: 72px 32px 38px 200px;
            width: 34vw;
            position: static;
        }
        &.hide {
            padding: 0 30px;
            @include xxlarge {
                padding: 72px 32px 38px 200px;
            }
        }
        #menu-main-navigation {
            list-style: none;
            @include xxlarge {
                position: static;
            }
            > li {
                @include xxlarge {
                    position: static;
                }
                &:last-child {
                    a {
                        margin-bottom: 0;
                    }
                }
                a {
                    color: $white;
                    font-family: garamond-atf-subhead, serif;
                    display: block;
                    font-size: 1.125rem;
                    margin-bottom: 6px;
                    padding: 10px 0;
                    padding-right: 24px;
                    @include medium {
                        display: table;
                        margin-bottom: 6px;
                        padding-right: 0;
                        padding: 5px 0;
                        font-size: 1.5rem;
                        border-bottom: 2px solid transparent;
                    }
                    @include xxlarge {
                        font-size: 1.75rem;
                        margin-bottom: 16px;
                        padding: 8px 0 2px;
                    }
                    &.hide {
                        display: none;
                        @include xxlarge {
                            display: table;
                        }
                    }
                    &:hover, &.active {
                        @include medium {
                            border-bottom: 2px solid $brown;
                        }
                    }
                }
                &.menu-item-has-children {
                    > a {
                        background-image: url('../images/caret-orange.svg');
                        background-position: right center;
                        background-repeat: no-repeat;
                        background-size: 14px 20px;
                        @include medium {
                            background-image: none;
                        }
                        &::after {
                            @include medium {
                                content: '';
                                background-image: url('../images/caret-orange.svg');
                                background-position: right center;
                                background-repeat: no-repeat;
                                background-size: 16px 20px;
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                right: -24px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            @include xxlarge {
                                content: none;
                            }
                        }
                    }
                }
            }
            .main-navigation__subsection {
                .nav-back {
                    font-family: museo-sans, sans-serif;
                    font-size: 1rem;
                    background-image: none;
                    margin-bottom: 20px;
                    @include xxlarge {
                        display: none;
                    }
                    svg {
                        transform: rotate(180deg);
                        margin-right: 8px;
                        path {
                            stroke: $white;
                        }
                    }
                    &.hide {
                        display: none;
                    }
                    &::after {
                        @include medium {
                            content: none;
                        }
                    }
                    &:hover {
                        @include medium {
                            border-bottom: 2px solid transparent;
                        }
                    }
                }
                .sub-menu {
                    list-style: none;
                    @include medium {
                        columns: 2;
                    }
                    @include xxlarge {
                        position: static;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        columns: auto;
                    }
                    > li {
                        @include medium {
                            flex-basis: 50%;
                        }
                        @include xxlarge {
                            flex-basis: auto;
                            position: static;
                        }
                        a {
                            font-size: 1.125rem;
                            margin-bottom: 6px;
                            @include medium {
                                margin-bottom: 6px;
                                display: inline-block;    
                            }
                            @include xxlarge {
                                font-size: 1.125rem;
                            }
                            &::after {
                                @include medium {
                                    background-size: 12px 20px;
                                }
                            }
                            &.hide {
                                @include medium {
                                    display: none;
                                }
                                @include xxlarge {
                                    display: inline-block;
                                }
                            }
                        }
                        &.menu-item-has-children {
                            a {
                                background-image: url('../images/caret-orange.svg');
                                background-position: right center;
                                background-repeat: no-repeat;
                                background-size: 14px 20px;
                                @include medium {
                                    background-image: none;
                                }
                                &::after {
                                    @include medium {
                                        content: '';
                                        background-image: url('../images/caret-orange.svg');
                                        background-position: right center;
                                        background-repeat: no-repeat;
                                        background-size: 16px 20px;
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        right: -24px;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                    @include xxlarge {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }
                    .main-navigation__subsection {
                        background-color: $pale;
                        .nav-back {
                            color: $cobalt-blue;
                            background-image: none;
                            &::after {
                                content: none;
                            }
                            svg {
                                path {
                                    stroke: $cobalt-blue;
                                }
                            }
                            @include xxlarge {
                                display: none;
                            }
                        }
                        .main-navigation__subsection-cta {
                            h5, p, a {
                                color: $cobalt-blue;
                            }
                            a {
                                margin-bottom: 0;
                                background-image: none;
                                &::after {
                                    content: none;
                                }
                                svg {
                                    path {
                                        stroke: $cobalt-blue;
                                    }
                                }
                            }
                        }
                        .sub-menu {
                            @include medium {
                                columns: auto;
                            }
                            > li {
                                a {
                                    color: $cobalt-blue;
                                    background-image: none;
                                    &::after {
                                        content: none;
                                    }
                                }
                            }
                        }
                        &.active {
                            @include xxlarge {
                                position: absolute;
                                height: 100%;
                                top: 0;
                                left: 100%;
                                width: 100%;
                                margin: 0;
                                padding: 72px 32px 38px 82px;
                            }
                        }
                    }
                }
                &.hide {
                    padding: 0 30px;
                    @include xxlarge {
                        padding: 72px 32px 38px 82px;
                    }
                    .sub-menu {
                        @include medium {
                            columns: auto;
                        }
                    }
                }
            }
            .main-navigation__subsection-cta {
                border-left: 2px solid $brown;
                padding: 5px 0 5px 22px;
                margin-bottom: 24px;
                @include medium {
                    margin-bottom: 33px;
                }
                @include xxlarge {
                    margin-bottom: 42px;
                }
                > a {
                    color: $white;
                    font-family: museo-sans, sans-serif;
                    margin: 0;
                    font-size: 1rem;
                    font-weight: $font-weight-med;
                    background-image: none;
                    @include medium {
                        font-size: 1.125rem;
                    }
                    &::after {
                        @include medium {
                            content: none;
                        }
                    }
                    svg {
                        margin-left: 8px;
                        path {
                            stroke: $white;
                        }
                    }
                }
                &.hide {
                    display: none;
                    @include xxlarge {
                        display: block;
                    }
                }
            }
        }
    }
    &__subsection {
        display: none;
        background-color: $night-blue;
        &.active {
            display: block;
            margin: 0 -30px;
            padding: 10px 30px 14px;
            @include medium {
                padding: 30px 30px 14px;
            }
            @include xxlarge {
                position: absolute;
                height: 100%;
                top: 0;
                left: 34vw;
                width: 33vw;
                margin: 0;
                padding: 72px 32px 38px 82px;
                z-index: 2;
            }
        }
        h5 {
            color: $white;
            font-size: 1.25rem;
            margin-bottom: 8px;
            @include medium {
                font-size: 1.5rem;
            }
            @include xxlarge {
                font-size: 1.75rem;
            }
        }
        p {
            color: $white;
            font-size: .875rem;
            line-height: 1.29;
            margin-bottom: 10px;
            @include medium {
                font-size: 1rem;
                line-height: 1.5;
                max-width: 700px;
            }
        }
    }
    &__misc-links {
        background-color: $cobalt-blue;
        display: flex;
        flex-direction: column;
        padding: 0 30px 24px;
        @include medium {
            padding: 0 56px 28px;
        }
        @include xxlarge {
            padding: 0 32px 100px 200px;
            width: 34vw;
            flex: 1;
        }
        &.hide {
            display: none;
            @include xxlarge {
                display: flex;
            }
        }
    }
    &__misc-link {
        display: block;
        color: $very-light-pink;
        font-size: .875rem;
        text-transform: uppercase;
        margin-bottom: 4px;
        font-weight: $font-weight-bold;
        letter-spacing: 1.4px;
        padding: 12px 0;
        @include medium {
            letter-spacing: 1.6px;
            font-size: 1rem;
            margin-bottom: 6px;
        }
        @include xxlarge {
            padding: 8px 0;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}
.card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin-bottom: 20px;
    flex: 1;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    max-width: 325px;
    min-height: 450px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    @include medium {
        max-width: 755px;
        padding: 18px;
        margin: 0 auto 30px; 
        min-height: auto;
        flex-direction: row;
    }
    @include xxlarge {
        padding: 0;
        min-height: 500px;
        max-width: 400px;
        margin-bottom: 32px;
        flex-direction: column;
    }
    .i72-image, .i72-svg {
        max-height: 190px;
        height: 60vw;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include medium {
            height: auto;
            min-height: 195px;
            max-height: none;
            width: 300px;
            min-width: 300px;
            border-radius: 2px;
        }
        @include xxlarge {
            height: 237px;
            width: 100%;
            border-radius: 0;
        }
        svg {
            height: 100%;
            width: 100%;
        }
    }
    .i72-image.stock {
        background-image: url('../images/news-placeholder.svg');
        background-size: contain !important;
    }
    &__inner-container {
        padding: 20px 26px 48px;
        flex: 1;
        min-height: 250px;
        @include medium {
            padding: 8px 0 40px 26px;
        }
        @include xxlarge {
            padding: 24px 32px 75px;
        }
    }
    &__title {
        font-family: garamond-atf-subhead, serif;
        margin: 0;
        margin-bottom: 4px;
        font-size: 1.125rem;
        color: $cobalt-blue;
        font-weight: $font-weight-med;
        @include medium {
            font-size: 1.25rem;
            margin-bottom: 6px;
        }
        @include xxlarge {
            font-size: 1.75rem;
            line-height: 1.29;
            margin-bottom: 12px;
        }
    }
    &__text {
        margin-bottom: 20px;
        color: $greyish-brown;
        font-size: .875rem;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        max-height: 14em;
        line-height: 1.4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        visibility: visible;
        @include medium {
            line-height: 1.5;
            -webkit-line-clamp: 5;
            max-height: 7.5em;
        }
        @include xxlarge {
            font-size: 1rem;
        }
    }
    &__link {
        position: absolute;
        color: $cobalt-blue;
        font-size: 1rem;
        font-weight: $font-weight-med;
        left: 26px;
        bottom: 20px;
        @include medium {
            left: 26px;
            bottom: 6px;
        }
        @include xxlarge {
            font-size: 1.25rem;
            left: 38px;
            bottom: 35px;
        }
        svg {
            margin-left: 12px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

.row.col-two {
    .card {
        @include medium {
            max-width: 672px;
            padding: 0;
            margin: 0 auto 54px; 
            min-height: auto;
            flex-direction: column;
        }
        @include xxlarge {
            padding: 0;
            min-height: 500px;
            margin: 0 0 32px 80px;
            max-width: 643px;
        }
        .i72-image, .i72-svg {
            max-height: 190px;
            height: 60vw;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            @include medium {
                height: 237px;
                min-height: auto;
                max-height: 237px;
                max-width: none;
                width: 100%;
                border-radius: 0;
            }
            svg {
                height: 100%;
                width: 100%;
            }
        }
        &__inner-container {
            padding: 20px 26px 48px;
            flex: 1;
            @include medium {
                padding: 24px 40px 75px;
            }
        }
        &__title {
            @include medium {
                font-size: 1.75rem;
                margin-bottom: 12px;
                line-height: 1.29;
            }
        }
        &__text {
            @include medium {
                font-size: 1rem;
                line-height: 1.5;
            }
        }
        &__link {
            @include medium {
                left: 40px;
                bottom: 40px;
                font-size: 1.25rem;
            }
        }
    }
}
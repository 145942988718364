.full-width {
    padding: 20px 0 18px;
    margin-bottom: 24px;
    @include medium {
        padding: 36px 0;
    }
    @include xlarge {
        margin-bottom: 40px;
    }
    @include xxlarge {
        padding: 45px 0;
    }
    &.contain {
        .full-width__image {
            background-size: contain;
        }
    }
    &.blue {
        background-color: $night-blue;
        .full-width__heading, .full-width__content p {
            color: $white;
        }
        .full-width__content {
            ol, ul {
                color: $white;
            }
            h1, h2, h3, h4, h5, h6 {
                color: $white;
            }
            a {
               color: $white;
               text-decoration: underline;
            }
        }
        .full-width__button {
            color: $brown;
            border-color: $brown;
            &:hover {
                background-color: $brown;
                color: $night-blue;
            }
        }
    }
    &.tan {
        background-color: $pale;
    }
    &.right {
        .full-width__image {
            @include medium {
                order: 2;
                margin-right: 0;
                margin-left: 48px;
            }
            @include xxlarge {
                margin-right: 0;
                margin-left: 68px;
            }
        }
        &.full-width-size {
            .full-width__image {
                display: none;
                @include xxlarge {
                    display: block;
                    margin-right: 0;
                    margin-left: 200px;
                }
            }
        }
    }
    &.full-width-size {
        .full-width__image {
            display: none;
            @include xxlarge {
                display: block;
                width: 550px;
                min-width: 300px;
                height: 220px;
                margin-right: 200px;
            }
        }
        .full-width__heading {
            font-size: 1.375rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            font-family: museo-sans, sans-serif;
            margin-bottom: 16px;
            letter-spacing: 3.3px;
            @include xxlarge {
                font-size: 1.875rem;
                margin-bottom: 30px;
            }
        }
        .full-width__buttons {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: stretch;
            margin-top: 32px;
            padding-bottom: 10px;
            @include medium {
                flex-direction: row;
                max-width: 675px;
                margin: 56px auto 0;
                justify-content: center;
            }
            @include xxlarge {
                margin: 56px 0 0;
                max-width: none;
                flex-wrap: nowrap;
                justify-content: flex-start;
            }
        }
        .full-width__buttons:empty {
            display: none;
        }
        .full-width__container {
            width: 100%;
        }
        .full-width__outer-container {
            padding: 0 28px;
            @include medium {
                padding: 0 40px;
            }
        }
        .full-width__button {
            @include medium {
                margin: 0 16px 32px
            }
            @include xxlarge {
                margin: 0 32px 0 0;
            }
            &:last-child {
                @include xxlarge {
                    margin: 0;
                }
            }
        }
        + .newsletter-form, + .row, + .full-width.full-width-size, + .background {
            margin-top: -24px;
            @include xlarge {
                margin-top: -40px;
            }
        }
    }
    &__image {
        width: 100%;
        height: 178px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 18px;
        @include medium {
            width: 260px;
            min-width: 260px;
            height: 260px;
            margin-bottom: 0;
            margin-right: 48px;
        }
        @include xxlarge {
            width: 550px;
            min-width: 550px;
            height: 307px;
            margin-right: 68px;
        }
    }
    &__outer-container {
        display: flex;
        flex-direction: column;
        padding: 12px 28px 12px;
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
        @include medium {
            flex-direction: row;
            padding: 16px 40px;
            max-width: 1010px;
        }
        @include xxlarge {
            max-width: 1600px;
        }
    }
    &__heading {
        font-size: 1.625rem;
        font-family: garamond-atf-subhead, serif;
        margin-bottom: 16px;
        @include medium {
            font-size: 2.25rem;
        }
        @include xxlarge {
            font-size: 2.625rem;
            margin-bottom: 20px;
        }
    }
    &__content {
        p, ol, ul {
            font-size: .875rem;
            margin-bottom: 20px;
            @include xxlarge {
                font-size: 1rem;
            }
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
        ol, ul {
            padding-left: 16px;
            li {
                margin-bottom: 6px; 
            }
        }
        a {
            color: $cobalt-blue;
            text-decoration: underline;
        }
    }
    &__buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
        padding-bottom: 10px;
        @include medium {
            margin-top: 24px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        @include xxlarge {
            margin-top: 40px;
        }
    }
    &__button {
        width: 210px;
        margin-bottom: 24px;
    }
    + .newsletter-form {
        margin-top: -18px;
        @include medium {
            margin-top: -50px;
        }
        @include xxlarge {
            margin-top: -65px;
        }
    }
}
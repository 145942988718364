.link-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    text-decoration: none;
    padding: 32px;
    flex: 1;
    min-width: 300px;
    max-width: 300px;
    min-height: 246px;
    margin: 0 10px 20px;
    background-color: $secondary-color;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.22);
    transition: box-shadow $trans-med ease;
    .i72-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        inset: 0;
    }
    &__overlay {
        position: absolute;
        z-index: 1;
        inset: 0;
        opacity: .5;
        transition: background-color $trans-med ease;
    }
    &__title {
        color: $white;
        z-index: 2;
        text-align: center;
        font-size: 2.25rem;
        line-height: 1.06;
        font-weight: $font-weight-semi;
    }
    &:hover, &:focus {
        box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.44);
        .overlay {
            background-color: rgba(155, 174, 181, .85);
        }
    }
}

.link-block {
    a {
        display: table;
        margin-bottom: 20px;
        @include medium {
            margin-bottom: 24px;
        }
        span {
            color: $night-blue;
            display: inline;
            font-size: 1.5rem;
            line-height: 1.33;
            border-bottom: 2px solid transparent;
            font-family: garamond-atf-subhead, serif;
            @include medium {
                font-size: 2rem;
                line-height: 1.3;
            }
            @include xxlarge {
                font-size: 2.625rem;
            }
        }
        svg {
            opacity: 0;
            display: inline-block;
            transition: opacity $trans-med ease;
            @include xxlarge {
                width: 28px;
                height: auto;
                margin-bottom: 3px;
                margin-left: 6px;
            }
        }
        &:hover {
            span {
                border-bottom: 2px solid $brown;
            }
            @include xxlarge {
                svg {
                    opacity: 1;
                }
            }
        }
    }
}
.banner {
    background-color: $night-blue;
    padding: 32px 12px;
    overflow: hidden;
    @include medium {
        padding: 48px 40px;
    }
    @include xlarge {
        padding: 80px 0;
    }
    &__container {
        max-width: 450px;
        margin: 0 auto;
        @include medium {
            max-width: 930px;
        }
        @include xxlarge {
            max-width: 1230px;
        }
        > h2 {
            color: $very-light-pink;
            text-transform: uppercase;
            font-family: museo-sans,sans-serif;
            font-weight: 900;
            letter-spacing: 3px;
            line-height: 1.3;
            font-size: 1.25rem;
            margin-bottom: 20px;
            max-width: 215px;
            width: 70%;
            @include medium {
                font-size: 1.875rem;
                margin-bottom: 32px;
                letter-spacing: 4.5px;
                max-width: 528px;
                width: auto;
            }
        }
    }
    .content {
        max-width: 215px;
        width: 70%;
        @include medium {
            max-width: 528px;
            width: auto;
        }
        p {
            color: $very-light-pink;
            font-size: 1rem;
            margin-bottom: 8px;
            a {
                font-weight: $font-weight-bold;
                color: $very-light-pink;
            }
        }
    }
    &::after {
        content: '';
        background-image: url('../images/many-crosses.svg');
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 155vw;
        max-width: 650px;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 1;
        @include medium {
            width: 90vw;
            max-width: 1735px;
        }
    }
}
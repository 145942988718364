.image-links {
    background-color: $grape-purple;
    padding: 16px;
    margin: 70px auto 52px;
    border-radius: 8px;
    max-width: 390px;
    width: 93.333%;
    @include medium {
        max-width: 754px;
        padding: 10px 70px 24px;
        margin: 125px auto 64px;
    }
    @include xlarge {
        width: 100%;
        max-width: 930px;
        margin-top: 145px;
    }
    @include xxlarge {
        padding: 10px 68px 68px;
        max-width: none;
        width: 100%;
        margin-top: 160px;
    }
    &__container {
        max-width: 1520px;
        margin: 0 auto;
        @include xxlarge {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 68px;
        }
    }
    &__content {
        padding: 30px 30px 4px;
        @include medium {
            padding: 47px 0 0;
        }
        @include xxlarge {
            padding: 58px 64px 0 4px;
        }
    }
    &__heading {
        font-family: garamond-atf-subhead, serif;
        font-size: 1.5rem;
        line-height: 1.25;
        color: $white;
        margin: 0 0 10px;
        color: $very-light-pink;
        @include medium {
            margin-bottom: 18px;
            font-size: 1.75rem;
        }
        @include xxlarge {
            font-size: 2.8125rem;
            line-height: 0.84;
            margin-bottom: 24px;
        }
    }
    &__links {
        @include xxlarge {
            margin-bottom: 32px
        }
    }
    &__link-container {
        display: flex;
    }
    &__icon {
        width: 20px;
        min-width: 20px;
        margin-right: 16px;
    }
    &__link {
        color: $white;
        display: block;
        font-size: .875rem;
        line-height: 1.43;
        font-weight: 300;
        margin-bottom: 24px;
        @include xxlarge {
            font-size: 1rem;
            line-height: 1.5;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    &__image {
        background-position: center;
        background-size: cover;
        margin-top: -40px;
        width: 100%;
        max-width: 340px;
        height: 230px;
        border-radius: 8px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        @include medium {
            margin-top: -84px;
            height: 423px;
            max-width: 612px;
        }
        @include xlarge {
            width: 788px;
            max-width: 788px;
        }
        @include xxlarge {
            order: 2;
            height: 460px;
            width: 60%;
        }
    }
    + .newsletter-form {
        margin-top: -52px;
        @include medium {
            margin-top: -64px;
        }
    }
}
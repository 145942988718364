.cta {
    width: 100%;
    background-color: $white;
    height: 100%;
    max-width: 450px;
    margin: 0 auto 32px;
    text-align: center;
    @include medium {
        margin-bottom: 40px;
    }
    @include xlarge {
        margin-bottom: 60px;
    }
    .i72-image, .i72-svg {
        max-height: 350px;
        height: 50vw;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include medium {
            height: 30vw;
        }
        svg {
            height: 100%;
            width: 100%;
        }
    }
    .i72-svg {
        padding: 12px;
    }
    &__inner-container {
        padding: 30px;
        @include medium {
            padding: 24px;
        }
        @include large {
            padding: 30px;
        }
    }
    &__title {
        margin-bottom: 20px;
        font-size: 2rem;
    }
    &__text {
        margin-bottom: 20px;
    }
}
.paragraph {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    flex: 1;
    @include medium {
        margin-bottom: 20px;
        flex-direction: row;
    }
    @include large {
        margin-bottom: 32px;
    }
    .i72-image, .i72-svg {
        max-height: 250px;
        height: 50vw;
        width: 100%;
        margin-bottom: 16px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include medium {
            height: 300px;
            width: 300px;
            margin: 0 32px 0 0;
        }
        svg {
            height: 100%;
            width: 100%;
        }
    }
    &__inner-container {
        color: $black;
        flex: 1;
        @include medium {
            padding: 18px 0;
        }
        @include large {
            padding: 20px 0;
        }
        @include xlarge {
            padding: 20px 0;
        }
    }
    &__title {
        margin-bottom: 20px;
        font-size: 2rem;
    }
    &__text {
        //
    }
    &__link {
        margin-top: 20px;
        border: 3px solid $cobalt-blue;
        font-size: 1rem;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        padding: 8px 12px;
        min-height: 48px;
        color: $cobalt-blue;
        width: 240px;
        font-weight: $font-weight-bold;
        text-align: center;
        transition: background-color $trans-med ease, color $trans-med ease, border-color $trans-med ease;
        @include medium {
            font-size: 1rem;
            letter-spacing: 1.6px;
        }
        @include xxlarge {
            width: 294px;
        }
        &:hover {
            background-color: $cobalt-blue;
            color: $white;
        }
    }
    &.paragraph--anchor {
        text-decoration: none;
    }
    &.page-width {
        max-width: 1520px;
        margin: 16px auto 32px;
        @include xlarge {
            margin: 20px auto 48px;
        }
    }
}

.row {
    .paragraph {
        @include medium {
            flex-direction: column;
        }
        .i72-image, .i72-svg {
            @include medium {
                height: 30vw;
                width: 100%;
                margin: 0 0 16px;
            }
        }
    }
}
.search-bar-container {
    display: none;
    background-color: $pale;
    justify-content: center;
    align-items: center;
    height: 0;
    overflow: hidden;
    transition: height $trans-med ease, visibility $trans-med ease;
    @include medium {
        display: flex;
        visibility: hidden;
    }
    .close-search-bar {
        position: absolute;
        right: 14px;
        bottom: -51px;
        z-index: 11;
        color: $black;
        text-decoration: none;
        letter-spacing: 1.6px;
        font-size: 1rem;
        font-weight: $font-weight-semi;
        @include medium {
            top: 30px;
            bottom: auto;
            right: 40px;
        }
        @include xlarge {
            top: 30px;
            right: 30px;
        }
        svg {
            top: 1px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    form {
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @include medium {
            width: 60%;
        }
        input[type="text"] {
            background-color: $white;
            border-radius: 20px;
            border: 0;
            font-size: 1rem;
            height: 48px;
            width: 70vw;
            border: 2px solid $brown;
            border-radius: 3px;
            max-width: 275px;
            padding: 12px 30px 12px 23px;
            margin-right: 14px;
            transition: border-color $trans-med ease;
            @include medium {
                width: 40vw;
                padding: 12px 40px 12px 24px;
                max-width: 420px;
            }
            @include xlarge {
                max-width: 515px;
            }
            @include xxlarge {
                max-width: 628px;
            }
        }
        .clear-search-input {
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
            display: none;
            &.active {
                display: block;
            }
        }
        > div {
            width: 70vw;
            max-width: 275px;
            margin-right: 14px;
            @include medium {
                width: 40vw;
                max-width: 420px;
            }
            @include xlarge {
                max-width: 515px;
            }
            @include xxlarge {
                max-width: 628px;
            }
        }
        input[type="submit"] {
            min-width: 48px;
            font-size: 0px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            background-image: url('../images/search-icon-blue.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 22px;
            border-radius: 5px;
            align-items: center;
            padding: 6px 12px;
            min-height: 48px;
            background-color: transparent;
            border: 3px solid $cobalt-blue;
            transition: background-color $trans-med ease, color $trans-med ease, border-color $trans-med ease;
            @include medium {
                font-size: 1rem;
                background-image: none;
                letter-spacing: 1.6px;
                text-transform: uppercase;
                color: $cobalt-blue;
                width: 157px;
                min-width: 157px;
                font-weight: $font-weight-bold;
                text-align: center;
            }
            @include xlarge {
                width: 209px;
                min-width: 209px;
            }
            &:hover {
                background-color: $cobalt-blue;
                color: $white;
                background-image: url('../images/search-icon-white.svg');
                @include medium {
                    background-image: none;
                }
            }
        }
    }
    &.active {
        visibility: visible;
        z-index: 999;
        height: 106px;
        overflow: visible;
        @include xlarge {
            height: 162px;
        }
    }
}

.search-results-container {
    max-height: 0;
    overflow: hidden;
    transition: all $trans-med ease;
    padding: 0 14px;
    background-color: $white;
    @include medium {
        padding: 0 42px;
    }
    @include xlarge {
        padding: 0 60px;
    }
    &.desktop {
        display: none;
        @include medium {
            display: block;
        }
    }
    &.mobile {
        @include medium {
            display: none;
        }
    }
    &.active {
        padding: 32px 14px 72px;
        max-height: 3000px;
        @include medium {
            padding: 36px 42px 80px;
        }
        @include xlarge {
            padding: 54px 60px 120px;
        }
    }
    .result-count {
        font-weight: $font-weight-bold;
        font-size: 1rem;
        padding-bottom: 16px;
        max-width: 1088px;
        display: block;
        border-bottom: solid 1px $brown;
        margin: 0 auto;
    }
    .result {
        padding: 20px 0;
        border-bottom: solid 1px $brown;
        max-width: 1088px;
        margin: 0 auto;
        @include medium {
            padding: 24px 0;
        }
        @include xxlarge {
            padding: 32px 0 38px;
        }
        .title {
            font-size: 1.375rem;
            color: $cobalt-blue;
            font-weight: $font-weight-semi;
            margin-bottom: 12px;
            @include medium {
                margin-bottom: 16px;
            }
            @include xxlarge {
                font-size: 1.875rem;
                max-width: 650px;
            }
        }
        .desc {
            line-height: 1.43;
            font-size: .875rem;
            margin-bottom: 16px;
            color: $greyish-brown;
            @include medium {
                margin-bottom: 24px;
            }
            @include xxlarge {
                font-size: 1rem;
                line-height: 1.5;
                max-width: 650px;
            }
        }
        a {
            span {
                display: inline;
                color: $cobalt-blue;
                font-size: 1rem;
                font-weight: $font-weight-semi;
                overflow-wrap: break-word;
                @include xxlarge {
                    font-size: 1.25rem;
                }
            }
            svg {
                width: 15px;
                display: inline-block;
                height: auto;
                margin-left: 6px;
                transition: margin-left $trans-med ease;
                @include xxlarge {
                    width: 18px;
                }
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .pagination-container {
        display: flex;
        justify-content: center;
        max-width: 1088px;
        padding-top: 16px;
        margin: 0 auto;
        @include xlarge {
            padding-top: 32px;
        }
        .prev-results, .next-results {
            color: $cobalt-blue;
            font-weight: $font-weight-bold;
            font-size: 1.125rem;
            padding: 16px 0;
            display: flex;
            align-items: center;
            @include medium {
                font-size: 1.25rem;
            }
            svg {
                border: 2px solid $cobalt-blue;
                border-radius: 5px;
                padding: 11px;
                width: 44px;
                height: 44px;
                margin-right: 15px;
                path {
                    fill: $cobalt-blue;
                }
            }
            &:hover {
                text-decoration: underline;
            }
        }
        .next-results {
            margin-left: auto;
            svg {
                margin-right: 0;
                margin-left: 15px;
                transform: rotate(270deg);
            }
        }
        .prev-results {
            margin-right: auto;
            svg {
                transform: rotate(90deg);
            }
        }
    }
    .close-search-results {
        position: absolute;
        left: 50%;
        bottom: 26px;
        transform: translateX(-50%);
        z-index: 11;
        color: $black;
        text-decoration: none;
        letter-spacing: 1.6px;
        font-size: 1rem;
        font-weight: $font-weight-semi;
        @include xlarge {
            left: auto;
            right: 64px;
            bottom: 50px;
        }
        svg {
            top: 1px;
        }
        &:hover {
            text-decoration: underline;
        }
        &.mobile {
            bottom: auto;
            left: auto;
            right: 14px;
            top: 32px;
            transform: none;
            @include medium {
                display: none;
            }
        }
    }
}

.mobile-search-box {
    background-color: $pale;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 106px;
    @include medium {
        display: none;
    }
    form {
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        input[type="text"] {
            background-color: $white;
            border-radius: 20px;
            border: 0;
            font-size: 1rem;
            height: 48px;
            width: 70vw;
            border: 2px solid $brown;
            border-radius: 3px;
            max-width: 275px;
            padding: 12px 30px 12px 23px;
            margin-right: 14px;
            transition: border-color $trans-med ease;
        }
        .clear-search-input {
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
            display: none;
            &.active {
                display: block;
            }
        }
        > div {
            width: 70vw;
            max-width: 275px;
            margin-right: 14px;
        }
        input[type="submit"] {
            min-width: 48px;
            font-size: 0px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            background-image: url('../images/search-icon-blue.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 22px;
            border-radius: 5px;
            align-items: center;
            padding: 6px 12px;
            min-height: 48px;
            background-color: transparent;
            border: 3px solid $cobalt-blue;
            transition: background-color $trans-med ease, color $trans-med ease, border-color $trans-med ease;
            &:hover {
                background-color: $cobalt-blue;
                color: $white;
                background-image: url('../images/search-icon-white.svg');
            }
        }
    }
}
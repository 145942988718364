.row {
    padding: 48px 0;
    @include medium {
        padding: 45px 0;
    }
    @include xxlarge {
        padding: 50px 0;
    }
    .row-cross {
        display: none;
    }
    &.has-cross {
        .row-cross {
            display: block;
            position: absolute;
            left: 50%;
            z-index: 2;
            bottom: -17px;
            width: 40px;
            height: auto;
            transform: translateX(-50%);
        }
    }
    &.tan {
        background-color: $pale;
        margin-bottom: 32px;
        @include medium {
            margin-bottom: 48px;
        }
        + .row.tan, + .row.white {
            margin-top: -32px;
            @include medium {
                margin-top: -48px;
            }
        }
    }
    .row__heading {
        width: calc(100% - 60px);
        font-family: museo-sans, sans-serif;
        font-size: 1.125rem;
        letter-spacing: 2.7px;
        color: $charcoal-grey;
        font-weight: 900;
        margin: 0 auto 36px;
        max-width: 1520px;
        text-align: center;
        overflow: hidden;
        @include medium {
            width: calc(100% - 80px);
            font-size: 1.75rem;
            letter-spacing: 4.2px;
            margin-bottom: 40px;
        }
        @include xlarge {
            width: calc(100% - 120px);
            margin-bottom: 48px;
        }
        @include xxlarge {
            letter-spacing: 5.1px;
            font-size: 2.125rem;
            margin-bottom: 64px;
            text-align: left;
        }
        span {
            font-weight: 900;
            text-transform: uppercase;
        }
        &.center {
            background-color: inherit;
            @include xxlarge {
                text-align: center;
            }
            span {
                padding: 0 10px;
                background-color: inherit;
                z-index: 2;
                @include medium {
                    padding: 0 30px;
                }
                @include xxlarge {
                    padding: 0 36px;
                }
            }
            &::before, &::after {
                content: '';
                position: absolute;
                height: 4px;
                background-color: $brown;
                width: 50%;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
            &::after {
                right: 50%;
            }
        }
    }
    .row__link {
        border: 3px solid $cobalt-blue;
        font-size: 1rem;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        padding: 8px 12px;
        min-height: 48px;
        color: $cobalt-blue;
        margin-top: 30px;
        width: 240px;
        font-weight: $font-weight-bold;
        text-align: center;
        transition: background-color $trans-med ease, color $trans-med ease, border-color $trans-med ease;
        @include medium {
            position: absolute;
            font-weight: $font-weight-semi;
            bottom: -20px;
            right: 30px;
            letter-spacing: normal;
            border: 0;
            padding: 0;
            margin: 0;
            width: auto;
            text-transform: none;
            min-height: auto;
        }
        @include xxlarge {
            right: 80px;
            bottom: -25px;
            font-size: 1.25rem;
        }
        svg {
            display: none;
            @include medium {
                display: block;
                margin-left: 8px;
            }
        }
        &:hover {
            background-color: $cobalt-blue;
            color: $white;
            @include medium {
                background-color: transparent;
                color: $cobalt-blue;
                text-decoration: underline;
            }
        }
    }
    .row-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include xxlarge {
            flex-direction: row;
            align-items: unset;
            flex-wrap: wrap;
            justify-content: center
        }
        .card, .paragraph {
            @include xxlarge {
                margin: 0 0 20px 85px;
            }
            &:first-child {
                @include xxlarge {
                    margin-left: 0;
                }
            }
        }
        .svg-icon-text-block {
            @include xxlarge {
                margin: 0 0 20px 64px;
            }
            &:first-child {
                @include xxlarge {
                    margin-left: 0;
                }
            }
        }
        .dual-event {
            @include xxlarge {
                padding: 0 65px;
            }
            &:first-child {
                @include xxlarge {
                    padding-right: 10px;
                }
            }
        }
        &.page-width {
            .wysiwyg {
                width: 100%;
            }
        }
    }
    + .newsletter-form {
        margin-top: -32px;
        @include medium {
            margin-top: -48px;
        }
    }
}
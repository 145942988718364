.single-events {
    .hero__content {
        padding: 0;
        text-align: left;
        @include medium {
            max-width: 1250px;
        }
        @include xxlarge {
            max-width: 1640px;
            width: 90%;
            left: auto;
            transform: none;
            position: relative;
        }
        .event {
            border: 0;
            max-width: none;
            @include xxlarge {
                padding: 55px 60px 55px 25px;
                max-width: 1640px;
            }
        }
        .event__container {
            max-width: calc(100% - 107px);
            @include medium {
                max-width: none;
            }
        }
        .event__event-date {
            @include xxlarge {
                padding: 0 20px 20px 30px;
            }
        }
        .event__event-info {
            @include xxlarge {
                padding: 0;
            }
            .btn {
                width: 209px;
                left: -60px;
                margin-top: 32px;
                @include medium {
                    left: 0;
                }
            }
        }
        .event__image.stock-image {
            background-image: url('../images/default.svg');
        }
    }
    .wysiwyg {
        h1, h2, h3, h4, h5, h6 {
            font-family: museo-sans,sans-serif;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;
            @include medium {
                letter-spacing: 3px;
            }
            @include xlarge {
                letter-spacing: 3.5px;
            }
        }
        ul {
            li {
                list-style: none;
                &::before {
                    content: '';
                    background-color: $twilight-blue;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    position: absolute;
                    top: 5px;
                    left: -16px;
                }
            }
        }
    }
}
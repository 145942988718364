.video {
    text-align: center;
    @include medium {
        margin: 0 24px;
    }
    iframe {
        height: 100%;
        width: 100vw;
        aspect-ratio: 16/9;
        max-width: 500px;
        @include medium {
            width: 80vw;
            max-width: 1366px;
        }
        @include xlarge {
            width: 75vw;
        }
    }
}

.page-container {
    .video.page-width {
        width: 100%;
        margin: 0 auto 48px;
        max-width: 1520px;
        @include medium {
            width: calc(100% - 80px);
            margin-bottom: 64px;
        }
        @include xlarge {
            width: calc(100% - 120px);
        }
    }
}

.row {
    .video {
        iframe {
            margin-bottom: 24px;
        }
    }
}

.video-cta {
    background-color: $grape-purple;
    padding: 16px;
    margin: 60px auto 52px;
    border-radius: 8px;
    max-width: 390px;
    width: 93.333%;
    background-image: url('../images/star.svg');
    background-position: 114% 107%;
    background-size: 114px;
    background-repeat: no-repeat;
    @include medium {
        max-width: 754px;
        padding: 10px 70px 54px;
        margin: 140px auto 64px;
        background-position: 109% 113%;
        background-size: 190px;
    }
    @include xlarge {
        width: 100%;
        max-width: 930px;
        background-position: 94% 113%;
    }
    @include xxlarge {
        display: flex;
        padding: 10px 68px 68px;
        align-items: center;
        justify-content: flex-end;
        max-width: 1520px;
        background-position: 103% 138%;
    }
    &__video {
        text-align: center;
        margin-top: -40px;
        @include medium {
            margin-top: -84px;
        }
        @include xxlarge {
            width: 60%;
            max-width: 788px;
            margin-top: 0;
            top: -84px;
            position: absolute;
            left: 68px;
        }
        iframe {
            border-radius: 8px;
            width: 100%;
            max-width: 340px;
            height: 230px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            @include medium {
                height: 423px;
                max-width: 612px;
            }
            @include xlarge {
                width: 788px;
                max-width: 788px;
            }
            @include xxlarge {
                height: 460px;
            }
        }
    }
    .i72-image {
        text-align: center;
        margin: 0 auto;
        margin-top: -40px;
        border-radius: 8px;
        width: 100%;
        max-width: 340px;
        height: 230px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        @include medium {
            margin-top: -84px;
            height: 423px;
            max-width: 612px;
        }
        @include xlarge {
            width: 788px;
            max-width: 788px;
        }
        @include xxlarge {
            width: 60%;
            min-width: 788px;
            height: 460px;
            margin-top: 0;
            top: -84px;
            position: absolute;
            left: 68px;
        }
    }
    &__cta-section {
        padding: 30px 30px 24px;
        @include medium {
            padding: 47px 0 0;
        }
        @include xxlarge {
            padding: 58px 0 0 64px;
            max-width: 600px;
            min-width: 600px;
        }
    }
    &__heading {
        font-family: garamond-atf-subhead, serif;
        font-size: 1.5rem;
        line-height: 1.25;
        color: $white;
        margin: 0 0 10px;
        color: $very-light-pink;
        @include medium {
            margin-bottom: 18px;
            font-size: 1.75rem;
        }
        @include xxlarge {
            font-size: 2.8125rem;
            line-height: 0.84;
            margin-bottom: 24px;
        }
    }
    &__content {
        margin-bottom: 46px;
        @include xxlarge {
            margin-bottom: 52px
        }
        p, ol, ul { 
            color: $white;
            font-size: .875rem;
            line-height: 1.43;
            font-weight: 300;
            @include medium {
                max-width: 575px;
            }
            @include xlarge {
                max-width: 715px;
            }
            @include xxlarge {
                font-size: 1rem;
                line-height: 1.5;
            }
        }
        ol, ul {
            padding-left: 16px;
            margin-bottom: 20px;
            li {
                margin-bottom: 6px; 
            }
        }
        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }
        a {
            color: inherit;
            text-decoration: underline;
        }
    }
    &__button {
        border-color: $white;
        color: $white;
        margin: 0 auto;
        @include medium {
            margin: 0;
        }
        &:hover {
            background-color: $white;
            color: $grape-purple;
        }
    }
    &__button:empty {
        display: none;
    }
}
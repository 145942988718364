.history_carousel {
    padding: 43px 34px;
    background-color: $night-blue;
    width: 100% !important;
    max-width: none !important;
    @include medium {
        padding: 70px 80px;
    }
    @include xxlarge {
        padding: 100px 80px;
    }
    &__container {
        max-width: 500px;
        margin: 0 auto;
        overflow: hidden;
        width: 85vw;
        @include medium {
            max-width: 930px;
            width: 80vw;
        }
        @include xxlarge {
            max-width: 1366px;
        }
    }
    &__heading {
        color: $white;
        font-weight: $font-weight-bold;
        font-size: 1.375rem;
        text-transform: uppercase;
        line-height: 1.18;
        letter-spacing: 1.76px;
        margin-bottom: 20px;
        text-align: center;
        @include medium {
            font-size: 1.875rem;
            line-height: 1.6;
            letter-spacing: 2.4px;
            margin-bottom: 56px;
        }
    }
    &__prev, &__next {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 30px;
        z-index: 9;
        @include medium {
            top: 65px;
        }
        span {
            display: none;
            @include medium {
                display: block;
                font-size: 1.25rem;
                color: $white;
                font-weight: $font-weight-med;
                margin-left: 16px;
            }
        }
        svg {
            transform: rotate(90deg);
            width: 44px;
            height: 44px;
            border: 2px solid #fff;
            padding: 12px;
            border-radius: 2px;
            transition: border-color $trans-med ease;
            path {
                fill: $white;
                transition: fill $trans-med ease;
            }
        }
        &:hover {
            svg {
                border-color: $brown;
                path {
                    fill: $brown;
                }
            }
        }
    }
    &__next {
        left: auto;
        right: 0;
        span {
            @include medium {
                margin-left: 0;
                margin-right: 16px;
            }
        }
        svg {
            transform: rotate(270deg);
        }
    }
    &__slides {
        list-style: none;
    }
    &__slide {
        display: block;
        float: left;
        max-width: 500px;
        width: 85vw;
        @include medium {
            width: 80vw;
            max-width: 930px;
        }
        @include xxlarge {
            max-width: 1366px;
        }
    }
    &__slide-content-container {
        @include xxlarge {
            display: flex;
            justify-content: space-between;
        }
    }
    &__slide-heading {
        font-size: 3.625rem;
        margin-bottom: 42px;
        font-weight: $font-weight-bold;
        font-style: italic;
        color: $white;
        line-height: 0.8;
        text-align: center;
        padding: 26px 0;
        border-top: 3px solid $brown;
        border-bottom: 3px solid $brown;
        @include medium {
            font-size: 6.25rem;
            padding: 40px 0;
            border-top: 5px solid $brown;
            border-bottom: 5px solid $brown;
        }
        @include xxlarge {
            margin-bottom: 62px;
        }
    }
    &__slide-content-header {
        font-family: museo-sans, sans-serif;
        color: $white;
        font-weight: $font-weight-med;
        font-style: italic;
        font-size: 1rem;
        margin-bottom: 14px;
        line-height: 1.38;
        @include medium {
            font-size: 1.125rem;
            width: 70%;
            max-width: 650px;
            margin-bottom: 16px;
            line-height: 1.44;
        }
        @include xxlarge {
            width: auto;
            max-width: 55%;
        }
    }
    &__slide-content {
        margin-bottom: 42px;
        @include medium {
            margin-bottom: 56px;
        }
        @include xxlarge {
            margin-bottom: 0;
        }
        p {
            color: $white;
            font-size: .875rem;
            margin-bottom: 16px;
            @include medium {
                font-size: 1rem;
            }
            strong {
                font-weight: $font-weight-bold;
            }
        }
    }
    &__img {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 197px;
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        @include xxlarge {
            margin: 0 0 0 68px;
            width: 320px;
            min-width: 320px;
        }
    }
    + .newsletter-form, + .row, + .full-width.full-width-size, + .background {
        margin-top: -24px;
        @include xlarge {
            margin-top: -40px;
        }
    }
}
.image {
    height: 50vw;
    width: 100%;
    min-height: 250px;
    max-height: 750px;
    .i72-image, .i72-svg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        svg {
            height: 100%;
            width: 100%; 
        }
    }
}
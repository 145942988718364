.news-filter {
    padding: 0 12px;
    max-width: 1680px;
    margin: 0 auto;
    @include medium {
        padding: 0 40px;
    }
    @include xlarge {
        padding: 0 80px;
    }
    &__button {
        background-color: $cobalt-blue;
        color: $white;
        width: 150px;
        height: 46px;
        padding: 6px 29px;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: $font-weight-semi;
        font-size: 1.125rem;
        margin-bottom: 40px;
        @include medium {
            width: 163px;
            height: 50px;
            font-size: 1.25rem;
            margin-bottom: 48px;
        }
        svg {
            transition: transform $trans-med ease
        }
        &.active {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    &__filters {
        border: 1px solid #d6d4d3;
        border-radius: 3px;
        padding: 26px 26px 2px;
        display: flex !important;
        flex-direction: column;
        margin-bottom: 40px;
        max-height: 700px;
        transition: all $trans-med ease;
        @include medium {
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 76px;
            padding: 36px 36px 12px 46px;
            max-width: 750px;
        }
        @include xxlarge {
            margin-bottom: 88px;
            max-width: 900px;
        }
        > p {
            font-size: 1.125rem;
            font-weight: $font-weight-semi;
            margin-bottom: 24px;
            color: $night-blue;
            @include medium {
                flex-basis: 100%;
            }
        }
        > label {
            font-size: 1rem;
            font-weight: $font-weight-med;
            margin-bottom: 24px;
            cursor: pointer;
            @include medium {
                margin-right: 36px;
            }
        }
        &.hidden {
            max-height: 0;
            overflow: hidden;
            padding: 0 26px;
            margin-bottom: 0;
            border-color: transparent;
            @include medium {
                padding: 0 36px 0 46px;
            }
        }
    }
}

.filter-date-container {
    @include xxlarge {
        display: flex;
        justify-content: flex-end;
        padding: 0 60px;
        max-width: 1640px;
        margin: 0 auto 92px;
    }
    h2 {
        font-size: 1.375rem;
        font-family: museo-sans, sans-serif;
        font-weight: 900;
        letter-spacing: 3.3px;
        text-transform: uppercase;
        padding: 0 12px;
        margin: 0 auto 34px;
        text-align: center;
        @include medium {
            text-align: left;
            padding: 0 40px;
            font-size: 1.75rem;
        }
        @include xlarge {
            padding: 0 60px;
        }
        @include xxlarge {
            font-size: 1.875rem;
            padding: 0;
            margin: 0 auto 0 0;
        }
    }
}

.filter-date-span {
    display: block;
    padding: 0 6px;
    margin: 0 auto 24px;
    font-size: 1.25rem;
    letter-spacing: 3px;
    font-weight: $font-weight-bold;
    color: $gray-dark;
    text-align: center;
    text-transform: uppercase;
    @include xxlarge {
        margin: 0;
        padding-top: 12px;
        margin-right: 285px;
    }
}
.filter-date-dropdown {
    height: 48px;
    max-width: 237px;
    margin: 0 auto 35px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
    border: 3px solid $brown;
    overflow: hidden;
    transition: height $trans-med ease;
    @include xxlarge {
        margin: 0;
        min-width: 237px;
        position: absolute; 
        top: 0;
        right: 60px;
        z-index: 2;
    }
    .filter-date-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 1.125rem;
        font-weight: $font-weight-med;
        color: $greyish-brown;
    }
    &.active {
        height: 276px;
        background-color: $white;
        visibility: visible;
        border-radius: 4px;
        .filter-date-title {
            display: none;
        }
        .filter-select {
            visibility: visible;
            svg {
                display: inline-block;
            }
        }
    }
    .filter-select {
        height: 100%;
        padding: 32px 0 86px;
        visibility: hidden;
        transition: visibility $trans-med ease;
        .select {
            height: 100%;
            width: 50%;
            float: left;
            margin: 0;
            text-align: center;
            padding: 0;
            border: 0;
            font-size: 1rem;
            outline: 0;
            -moz-appearance: none; 
            -webkit-appearance: none; 
            appearance: none;
            background: $white;
            overflow: scroll;
            list-style-type: none;
            &:focus {
                outline: -webkit-focus-ring-color auto 1px !important;
            }
            .option {
                display: block;
                padding: 6px 0;
                margin: 0 14px;
                color: $greyish-brown;
                font-weight: $font-weight-med;
                &.selected {
                    background: linear-gradient($white, $white);
                    border-top: 2px solid $cobalt-blue;
                    border-bottom: 2px solid $cobalt-blue;
                    padding: 4px 0;
                }
                &:hover {
                    background-color: $pale;
                }
            }
        }
        svg {
            position: absolute;
            width: 20px;
            display: none;
            &:first-of-type {
                left: calc(25% - 10px);
                top: 10px;
                transform: rotate(180deg);
            }
            &:nth-of-type(3) {
                left: calc(75% - 10px);
                top: 10px;
                transform: rotate(180deg);
            }
            &:nth-of-type(2) {
                left: calc(25% - 10px);
                bottom: 58px;
            }
            &:last-of-type {
                left: calc(75% - 10px);
                bottom: 58px;
            }
        }
        .filter-ok, .filter-clear {
            position: absolute;
            bottom: 0;
            width: 50%;
            left: 0;
            height: 36px;
            font-size: 1rem;
            color: $greyish-brown;
            font-weight: $font-weight-med;
            background-color: #fff;
            transition: background-color $trans-med ease, color $trans-med ease;
            border: 0;
            cursor: pointer;
            &:hover {
                background-color: $pale;
            }
            &:active {
                background-color: $cobalt-blue;
                color: $white;
            }
        }
        .filter-ok {
            left: 50%;
        }
    }
}

.news-list, .latest-posts {
    padding: 0 12px;
    @include medium {
        padding: 0 40px;
    }
    @include xlarge {
        padding: 0 60px;
    }
    @include xxlarge {
        max-width: 1640px;
        margin: 0 auto;
    }
    .news {
        display: flex;
        width: 100%;
        max-width: 500px;
        margin: 0 auto 50px;
        @include medium {
            max-width: 900px;
            margin-bottom: 63px;
        }
        @include xxlarge {
            max-width: none;
            margin: 0 0 108px;
            width: 80%;
        }
        &__image {
            width: 100px;
            min-width: 100px;
            height: 100px;
            margin-right: 20px;
            border-radius: 3px;
            background-image: url('../images/news-placeholder.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: $pale;
            @include medium {
                width: 190px;
                min-width: 190px;
                height: 190px;
                margin-right: 36px;
            }
            @include xxlarge {
                width: 280px;
                min-width: 280px;
                height: 280px;
                margin-right: 56px;
            }
        }
        &__container {
            width: 100%;
            padding-top: 2px;
        }
        &__title {
            font-size: 1.125rem;
            color: $charcoal-grey;
            line-height: 1.22;
            margin-bottom: 11px;
            padding-top: 13px;
            @include medium {
                font-size: 1.5rem;
                line-height: 1.25;
                padding-top: 16px;
            }
            @include xxlarge {
                font-size: 2.125rem;
                line-height: 1.18;
                padding-top: 18px;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 67px;
                height: 3px;
                background-color: $brown;
                @include xxlarge {
                    height: 5px;
                }
            }
        }
        &__meta {
            font-size: .875rem;
            color: #3c3c3b;
            font-weight: $font-weight-bold;
            margin-bottom: 16px;
            @include medium {
                font-size: 1rem;
                margin-bottom: 19px;
            }
            @include xxlarge {
                font-size: 1.125rem;
            }
            span {
                margin: 0 10px;
                font-size: 1rem;
                font-weight: $font-weight-bold;
                @include medium {
                    margin: 0 14px;
                    font-size: 1.125rem;
                }
                @include xxlarge {
                    font-size: 1.25rem;
                }
            }
        }
        &__excerpt {
            font-size: .875rem;
            line-height: 1.43;
            margin-bottom: 16px;
            @include medium {
                font-size: 1rem;
                line-height: 1.5;
                margin-bottom: 24px;
            }
            @include xxlarge {
                font-size: 1.125rem;
            }
        }
        &__link {
            color: $cobalt-blue;
            font-size: 1rem;
            font-weight: $font-weight-med;
            background-image: url('../images/arrow.svg');
            background-position: right center;
            background-repeat: no-repeat;
            padding-right: 24px;
            background-size: 16px;
            @include medium {
                font-size: 1.25rem;
                padding-right: 26px;
                background-size: 18px;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.latest-posts {
    padding: 0;
    > h3 {
        font-size: 1.375rem;
        font-family: museo-sans, sans-serif;
        font-weight: 900;
        letter-spacing: 3.3px;
        text-transform: uppercase;
        padding: 0 12px;
        margin: 0 auto 34px;
        text-align: center;
        @include medium {
            text-align: left;
            padding: 0;
            font-size: 1.75rem;
            margin-bottom: 55px;
        }
        @include xlarge {
            padding: 0;
        }
        @include xxlarge {
            font-size: 1.875rem;
            padding: 0;
            margin: 0 0 72px;
        }
    }
    .news {
        max-width: 500px;
        margin: 0 0 50px;
        @include medium {
            max-width: 900px;
            margin-bottom: 63px;
        }
        @include xxlarge {
            max-width: none;
            margin: 0 0 108px;
            width: 80%;
        }
    }
}

.archive-news {
    .pagination {
        width: 100%;
        padding: 0 12px;
        max-width: 1168px;
        margin: 0 auto 32px;
        @include medium {
            padding: 0 40px;
        }
        @include xlarge {
            margin-top: 100px;
        }
        &__buttons {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            @include xlarge {
                align-items: center;
                height: 120px;
            }
        }
        &__prev-button, &__next-button {
            color: $cobalt-blue;
            font-weight: $font-weight-bold;
            font-size: 1.125rem;
            padding: 16px 0;
            display: flex;
            align-items: center;
            @include medium {
                font-size: 1.25rem;
            }
            @include xlarge {
                padding: 0;
                position: absolute;
                top: 0;
            }
            svg {
                border: 2px solid $cobalt-blue;
                border-radius: 5px;
                padding: 11px;
                width: 44px;
                height: 44px;
                margin-right: 15px;
                transition: background-color $trans-med ease;
                path {
                    fill: $cobalt-blue;
                    transition: fill $trans-med ease;
                }
            }
            &:hover {
                svg {
                    background-color: $cobalt-blue;
                    path {
                        fill: $white;
                    }
                }
            }
        }
        &__next-button {
            margin-left: auto;
            @include xlarge {
                right: 0;
            }
            svg {
                margin-right: 0;
                margin-left: 15px;
                transform: rotate(270deg);
            }
        }
        &__prev-button {
            margin-right: auto;
            @include xlarge {
                left: 0;
            }
            svg {
                transform: rotate(90deg);
            }
        }
        &__indicator-buttons {
            display: flex;
            margin-top: 12px;
            margin-bottom: 20px;
            order: 2;
            flex-basis: 100%;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            @include xlarge {
                order: inherit;
                flex-basis: auto;
                margin: 0;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
            }
        }
        &__indicator-button {
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $gray-dark;
            margin: 0 8px;
            border: 1px solid $gray;
            &.active {
                width: 48px;
                height: 48px;
                color: $black;
                border: 1px solid $black;
                font-weight: 900;
            }
            &.link {
                transition: color $trans-med ease, border-color $trans-med ease;
                &:hover {
                    color: $cobalt-blue;
                    border-color: $cobalt-blue;
                }
            }
            &:empty {
                display: none;
            }
        }
        &__select-container {
            border: 1px solid $cobalt-blue;
            padding: 8px 12px;
            border-radius: 3px;
        }
        &__select {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-basis: 100%;
            margin: 20px 0 0;
            label {
                color: $cobalt-blue;
                font-weight: $font-weight-med;
                font-size: .875rem;
                margin-right: 12px;
            }
            select {
                border: 0;
                cursor: pointer;
                padding-right: 14px;
            }
        }
    }
}
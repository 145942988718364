.newsletter-form {
    padding: 40px 28px 68px;
    width: 100%;
    margin: 0 auto;
    @include medium {
        padding: 130px 28px;
        background-image: url('../images/three-crosses.svg');
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    @include xlarge {
        background-size: 1200px;
    }
    &__content {
        text-align: center;
        max-width: 450px;
        margin: 0 auto;
        @include medium {
            text-align: left;
            max-width: 670px;
        }
        h3 {
            font-family: garamond-atf-subhead, serif;
            color: $twilight-blue;
            text-transform: uppercase;
            font-size: 1.25rem;
            letter-spacing: 3px;
            line-height: 1.3;
            margin-bottom: 24px;
            @include medium {
                font-size: 1.5rem;
                letter-spacing: 3.6px;
                text-align: center;
            }
        }
        p {
            line-height: 1.5;
            font-size: 1rem;
        }
    }
    form {
        margin-top: 36px;
        max-width: 450px;
        margin: 36px auto 0;
        @include medium {
            text-align: left;
            max-width: 670px;
            margin-top: 20px;
            flex-direction: row;
        }
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            @include medium {
                flex-direction: row;
                align-items: flex-end;
            }
        }
        input[type="email"] {
            border: 3px solid $brown;
            background-color: transparent;
            font-size: 1rem;
            padding: 6px 23px;
            border-radius: 5px;
            height: 48px;
            width: 100%;
            margin-bottom: 24px;
            max-width: 319px;
            @include medium {
                margin-bottom: 0;
                max-width: none;
            }
        }
        .btn {
            width: 210px;
            min-width: 210px;
            background-color: $white;
            cursor: pointer;
            height: 48px;
            @include medium {
                margin-left: 16px;
            }
            &:hover {
                background-color: $cobalt-blue;
            }
        }
        ::-webkit-input-placeholder {
            font-style: italic;
         }
         :-moz-placeholder {
            font-style: italic;  
         }
         ::-moz-placeholder {
            font-style: italic;  
         }
         :-ms-input-placeholder {  
            font-style: italic; 
         }
         ::placeholder {
            font-style: italic; 
         }
    }
}
.multi-cta {
    &.full-style {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 72px 36px 84px;
        max-width: none;
        width: 100%;
        @include medium {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 85px 60px;
        }
        @include xxlarge {
            padding: 113px 100px;
            align-items: flex-end;
        }
        &.tan {
            .multi-cta__container {
                background-color: $pale;
            }
        }
        &.red {
            .multi-cta__container {
                background-color: $grape-purple;
                .multi-cta__buttons {
                    .multi-cta__button {
                        color: $white;
                        border-color: $white;
                        &:hover {
                            background-color: $white;
                            color: $cobalt-blue;
                        }
                    }
                }
            }
        }
        &.blue {
            .multi-cta__container {
                background-color: $cobalt-blue;
                .multi-cta__buttons {
                    .multi-cta__button {
                        color: $night-blue;
                        border-color: $brown;
                        &:hover {
                            background-color: $night-blue;
                            color: $brown;
                            border-color: $night-blue;
                        }
                    }
                }
            }
        }
        &.last {
            margin-bottom: -32px;
        }
        .multi-cta__container {
            background-color: $white;
            padding: 31px 32px 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            @include medium {
                max-width: 612px;
                padding: 48px 60px 30px;
            }
            @include xxlarge {
                max-width: 817px;
                flex-direction: row;
                align-items: flex-end;
                top: 113px;
                right: calc(50% - 282px);
                -webkit-transform: translateX(-50%);
                transform: translateX(50%);
                position: absolute;
            }
            .multi-cta__text-section {
                padding: 0;
                background-color: transparent;
                margin-bottom: 16px;
                @include xxlarge {
                    margin-right: 45px;
                }
                .multi-cta__heading {
                    font-size: 1.375rem;
                    font-family: garamond-atf-subhead, serif;
                    margin: 0 0 6px;
                    line-height: 1.18;
                    padding: 0;
                    border: 0;
                    @include medium {
                        font-size: 1.75rem;
                        margin-bottom: 10px;
                    }
                    @include xxlarge {
                        font-size: 2.625rem;
                        margin-bottom: 24px;
                    }
                }
                .multi-cta__content {
                    p {
                        font-size: .875rem;
                        font-weight: 300;
                        line-height: 1.43;
                        margin-bottom: 20px;
                        @include xxlarge {
                            font-size: 1rem;
                            margin-bottom: 24px;
                        }
                    }
                }
            }
            .multi-cta__buttons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0;
                @include xxlarge {
                    align-items: flex-end;
                    margin-bottom: 16px;
                    width: auto;
                    min-width: auto;
                }
                &::after {
                    content: none;
                }
                .multi-cta__button {
                    margin-bottom: 16px;
                    color: $cobalt-blue;
                    border-color: $cobalt-blue;
                    @include medium {
                        width: 294px;
                    }
                    @include xxlarge {
                        margin-bottom: 28px;
                    }
                    &:hover {
                        background-color: $cobalt-blue;
                        color: $white;
                    }
                }
            }
        }
    }
    display: flex;
    flex-direction: column;
    max-width: 930px;
    width: 100%;
    margin: 0 auto 60px;
    @include medium {
        margin-bottom: 70px;
    }
    @include xxlarge {
        max-width: 1520px;
        flex-direction: row;
        min-height: 415px;
        margin-bottom: 80px;
    }
    &__text-section {
        padding: 31px 50px;
        background-color: $pale;
        @include medium {
            padding: 58px 5vw 72px;
        }
        @include xxlarge {
            padding: 108px 124px;
        }
    }
    &__heading {
        font-size: 1.75rem;
        display: inline-block;
        padding-bottom: 3px;
        border-bottom: 3px solid $brown;
        margin-bottom: 20px;
        @include medium {
            font-size: 2.625rem;
            padding-bottom: 6px;
            border-width: 6px;
        }
    }
    &__content {
        p {
            font-size: 1rem;
            line-height: 1.5; 
            font-weight: 300;  
        } 
    }
    &__buttons {
        display: flex;
        flex-direction: column;
        padding: 68px 40px 40px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        justify-content: center;
        align-items: center;
        @include xxlarge {
            width: 550px;
            min-width: 550px;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(14, 1, 76, .6);
        }
    }
    &__button {
        margin-bottom: 28px;
        z-index: 2;
        color: $white;
        border-color: $white;
        &:hover {
            background-color: $white;
            color:rgb(14,1,76);;
        }
    }
    &.blue {
        .multi-cta__text-section {
            background-color: $night-blue;
        }
        .multi-cta__heading {
            color: $white;
        }
        .multi-cta__content {
            p {
                color: $white;
            }
        }
        .multi-cta__buttons {
            &::after {
                background-color: rgba(0, 12, 153, .71);
            }
        }
        .multi-cta__button {
            color: $night-blue;
            background-color: $brown;
            border-color: $brown;
            &:hover {
                background-color: $night-blue;
                color: $brown;
                border-color: $night-blue;
            }
        }
    }
    &.red {
        .multi-cta__text-section {
            background-color: $grape-purple;
        }
        .multi-cta__heading {
            color: $white;
        }
        .multi-cta__content {
            p {
                color: $white;
            }
        }
    }
    + .newsletter-form {
        margin-top: -60px;
        @include medium {
            margin-top: -70px;
        }
        @include xxlarge {
            margin-top: -80px;
        }
    }
}
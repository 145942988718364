.page-container {
    .role-links {
        display: flex;
        flex-direction: column;
        margin-bottom: 70px;
        @include medium {
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 48px;
        }
        @include xxlarge {
            padding: 0 52px;
        }
        &__heading {
            font-size: 1.25rem;
            margin-bottom: 10px;
            letter-spacing: 3px;
            font-weight: $font-weight-bold;
            color: $gray-dark;
            @include medium {
                min-width: 130px;
                line-height: 1.5;
            }
        }
        &__container {
            display: flex;
            flex-direction: column;
            @include medium {
                flex-direction: row;
                flex-wrap: wrap;
                padding-left: 24px;
            }
            @include xxlarge {
                max-width: 920px;
                min-width: 920px;
                padding-left: 32px;
            }
        }
        &__link {
            margin-bottom: 12px;
            display: table;
            @include medium {
                margin-bottom: 22px;
                flex-basis: 50%;
                padding-right: 4%;
            }
            @include xxlarge {
                margin-bottom: 18px;
                flex-basis: auto;
                padding-right: 4%;
            }
            span {
                display: inline;
                color: $cobalt-blue;
                font-size: 1rem;
                font-weight: $font-weight-semi;
                @include medium {
                    font-size: 1.25rem;
                    line-height: 1.5;
                }
            }
            svg {
                display: inline-block;
                margin-left: 4px;
                width: 15px;
                height: auto;
                path {
                    stroke: $cobalt-blue;
                }
            }
            &:hover {
                span {
                    text-decoration: underline;
                }
            }
        }
    }
    .staff-list {
        > h2 {
            color: $twilight-blue;
            font-size: 1.375rem;
            line-height: 1.27;
            letter-spacing: 3.3px;
            text-transform: uppercase;
            margin: 0 0 28px;
            padding-top: 28px;
            padding-left: 48px;
            background-image: url('../images/cross.svg');
            background-position: left 28px;
            background-repeat: no-repeat;
            background-size: 29px;
            @include xlarge {
                font-size: 1.875rem;
                padding-left: 56px;
                padding-top: 34px;
                background-size: 40px;
                max-width: 1365px;
                margin: 0 auto 34px;
                background-position: left 34px;
            }
        }
        .staff {
            margin: 0 -30px;
            background-color: $pale;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 56px 30px;
            @include medium {
                margin: 0 -40px;
                padding: 56px 40px;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: row;
            }
            @include xlarge {
                max-width: 1365px;
                margin: 0 auto;
                padding: 54px;
            }
            &:nth-child(even) {
                background-color: $white;
            }
            &__image {
                @include medium {
                    margin-right: 48px;
                }
                @include xlarge {
                    margin-right: 0;
                }
                .i72-image {
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 207px;
                    height: 269px;
                    margin-bottom: 36px;
                    @include medium {
                        width: 300px;
                        height: 390px;
                        margin: 0;
                    }
                    @include xlarge {
                        margin-right: 44px;
                    }
                }
            }
            &__content {
                @include medium {
                    text-align: left;
                }
            }
            &__title {
                font-size: 1.375rem;
                color: $gray-dark;
                line-height: 1.09;
                margin-bottom: 20px;
                @include xlarge {
                    font-size: 1.875rem;
                    margin-bottom: 12px;
                }
            }
            &__jobs {
                p {
                    font-size: .875rem;
                    line-height: 1.43;
                    font-style: italic;
                    font-weight: $font-weight-semi;
                    color: $gray-dark;
                    @include xlarge {
                        font-size: 1rem;
                        line-height: 1.5;
                    }
                }
            }
            &__education {
                p {
                    font-size: .875rem;
                    line-height: 1.43;
                    color: $gray-dark;
                    @include xlarge {
                        font-size: 1rem;
                        line-height: 1.5;
                    }
                }
            }
            &__contact-info {
                display: flex;
                flex-direction: column;
                margin-bottom: 22px;
                a {
                    display: table;
                    color: $cobalt-blue;
                    font-size: .875rem;
                    font-weight: $font-weight-semi;
                    text-decoration: underline;
                    margin-bottom: 10px;
                    @include xlarge {
                        font-size: 1rem;
                        line-height: 1.5;
                    }
                }
            }
            &__link {
                width: 209px;
                margin: 0 auto;
                @include medium {
                    margin: 0;
                }
            }
        }
    }
}

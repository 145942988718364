.quicklinks {
    margin-bottom: 24px;
    padding: 35px;
    @include medium {
        padding: 35px 60px;
        max-width: 1640px;
        margin: 0 auto 40px;
    }
    &__heading {
        font-size: 1.875rem;
        font-weight: 900;
        margin-bottom: 36px;
        letter-spacing: 4.5px;
        text-transform: uppercase;
        font-family: museo-sans, sans-serif;
        @include xxlarge {
            font-size: 2.25rem;
        }
    }
    &__container {
        @include medium {
            max-width: 700px;
            display: flex;
            flex-wrap: wrap;
        }
        @include xlarge {
            max-width: 1250px;
            align-items: center;
        }
    }
    &__link {
        display: block;
        margin-bottom: 28px;
        padding-right: 24px;
        @include medium {
            flex-basis: 45%;
        }
        @include xxlarge {
            flex-basis: 28%;
            margin-left: 5.333%;
        }
        &:nth-child(even) {
            @include medium {
                margin-left: 10%;
            }
            @include xxlarge {
                margin-left: 5.333%;
            }
        }
        &:nth-child(3n + 1) {
            @include xxlarge {
                margin-left: 0;
            }
        }
        span {
            color: $cobalt-blue;
            font-size: 1.25rem;
            font-weight: 500;
            display: inline;
        }
        svg {
            margin-left: 6px;
            margin-top: 6px;
            display: inline;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}
/* ignition72 Base */
*,
* * {
	margin:0;
	padding:0;
	position:relative;
	box-sizing: border-box;
}

p {
	font-size: 1rem;
    margin-bottom: 16px;
    line-height: 1.33;
    @include large {
        font-size: 1.125rem;
        margin-bottom: 20px;
        line-height: 1.5;
    }
}

/*	ensure HTML5 elements are handled as block level on older browsers */
footer,
header,
nav,
main,
section {
	display: block;
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

* {
	font-family: museo-sans, sans-serif;
    font-weight: 300;
    font-style: normal;
}

html, body {
    height: 100%;
    width: 100%;
	overflow-x: hidden;
}

body {
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.link-button {
    &.left {
        text-align: left;
    }
    &.align-center {
        text-align: center;
    }
    &.align-right {
        text-align: right;
    }
}

.hidden {
    display: none !important;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.btn {
    border: 3px solid $cobalt-blue;
    font-size: 1rem;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    padding: 8px 12px;
    min-height: 48px;
    color: $cobalt-blue;
    width: 240px;
    font-weight: $font-weight-bold;
    text-align: center;
    transition: background-color $trans-med ease, color $trans-med ease, border-color $trans-med ease;
    @include medium {
        font-size: 1rem;
        letter-spacing: 1.6px;
    }
    @include xxlarge {
        width: 294px;
    }
    &:hover {
        background-color: $cobalt-blue;
        color: $white;
    }
}

.page-container {
    background-color: $white;
    padding-bottom: 32px;
    > .page-width {
        width: calc(100% - 60px);
        margin: 0 auto 24px;
        max-width: 1520px;
        @include medium {
            width: calc(100% - 80px);
        }
        @include xlarge {
            width: calc(100% - 120px);
            margin-bottom: 40px;
        }
    }
    > ol, > ul {
        margin-bottom: 16px;
        li {
            margin-bottom: 8px;
            font-size: 1rem;
            line-height: 1.33;
            @include large {
                font-size: 1.125rem;
                line-height: 1.5;
            }
        }
    }
    > p, > h1, > h2, > h3, > h4, > h5, > h6, > ol, > ul {
        width: calc(100% - 60px);
        margin: 0 auto 16px;
        max-width: 1520px;
        @include medium {
            width: calc(100% - 80px);
            margin-bottom: 20px;
        }
        @include xlarge {
            width: calc(100% - 120px);
            margin-bottom: 24px;
        }
    }
    > p {
        a {
            color: $cobalt-blue;
            text-decoration: underline;
        }
    }
}

strong {
    font-weight: $font-weight-bold;
}

em {
    font-style: italic;
}

.row-container.page-width {
    width: calc(100% - 60px);
    margin: 0 auto;
    max-width: 1520px;
    @include medium {
        width: calc(100% - 80px);
    }
    @include xlarge {
        width: calc(100% - 120px);
    }
}


// HEADINGS //
h1 {
    font-size: 2.25rem;
    font-family: garamond-atf-subhead, serif;
    @include medium {
        font-size: 2.625rem;
    }
    @include xlarge {
        font-size: 3.25rem;
    }
}

h2 {
    font-size: 2rem;
    font-family: garamond-atf-subhead, serif;
    @include medium {
        font-size: 2.375rem;
    }
    @include xlarge {
        font-size: 3rem;
    }
}

h3 {
    font-size: 1.75rem;
    font-family: garamond-atf-subhead, serif;
    @include medium {
        font-size: 2rem;
    }
    @include xlarge {
        font-size: 2.25rem;
    }
}

h4 {
    font-size: 1.625rem;
    font-family: garamond-atf-subhead, serif;
    @include medium {
        font-size: 1.875rem;
    }
    @include xlarge {
        font-size: 2.125rem;
    }
}

h5 {
    font-size: 1.5rem;
    font-family: garamond-atf-subhead, serif;
    @include medium {
        font-size: 1.625rem;
    }
    @include xlarge {
        font-size: 2rem;
    }
}

h6 {
    font-size: 1.33rem;
    @include medium {
        font-size: 1.5rem;
    }
    @include xlarge {
        font-size: 1.875rem;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 12px;
    @include xlarge {
        margin-bottom: 16px;
    }
}

.wysiwyg {
    width: calc(100% - 60px);
    margin: 0 auto 24px;
    max-width: 1520px;
    @include medium {
        width: calc(100% - 80px);
    }
    @include xlarge {
        width: calc(100% - 120px);
        margin-bottom: 40px;
    }
    ul, ol {
        padding-left: 16px;
        margin-bottom: 20px;
        li {
            margin-bottom: 8px;
        }
    }
    ol {
        padding-left: 18px;
    }
    em {
        font-style: italic;
        font-family: inherit;
    }
    strong {
        font-weight: $font-weight-bold;
        font-family: inherit;
    }
    a {
        color: $cobalt-blue;
        text-decoration: underline;
    }
}

.old-temp {
    .page-container {
        width: calc(100% - 60px);
        margin: 0 auto 32px;
        max-width: 1520px;
        @include medium {
            width: calc(100% - 80px);
            margin-bottom: 56px;
        }
        @include xlarge {
            width: calc(100% - 120px);
            margin-bottom: 72px;
        }
        > p, > h1, > h2, > h3, > h4, > h5, > h6, > ol, > ul {
            width: 100%;
            margin: 0 auto 16px;
            max-width: 1520px;
            @include medium {
                margin-bottom: 20px;
            }
            @include xlarge {
                margin-bottom: 24px;
            }
        }
        > p {
            a {
                color: $cobalt-blue;
                text-decoration: underline;
            }
        }
    }
}

.partial-width_inner-container {
    &.half-width {
        @include medium {
            width: 50%;
        }
    }
    &.quarter-width {
        @include medium {
            width: 33%;
        }
        @include xlarge {
            width: 25%;
        }
    }
    &.three-quarter-width {
        @include medium {
            width: 75%;
        }
    }
}
.single-post {
    .hero__content {
        text-align: left;
        padding: 20px 16px;
        @include medium {
            max-width: 1250px;
            padding: 26px 40px;
        }
        @include xxlarge {
            max-width: 1640px;
            width: 90%;
            left: auto;
            transform: none;
            position: relative;
            padding: 58px 60px;
        }
        .news-details {
            font-size: .875rem;
            text-align: center;
            @include medium {
                text-align: left;
                font-size: 1rem;
            }
            @include xxlarge {
                font-size: 1.125rem;
            }
            span {
                font-weight: $font-weight-bold;
            }
        }
        .news-details__seperator {
            margin: 0 8px;
        }
    }
    .hero__title {
        margin-bottom: 10px;
        @include xxlarge {
            padding-top: 27px;
            margin-bottom: 12px;
        }
        &::before {
            @include xxlarge {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 5px;
                width: 67px;
                background-color: $brown;
            }
        }
    }
    .page-container {
        width: calc(100% - 60px);
        margin: 0 auto 24px;
        max-width: 1520px;
        @include medium {
            width: calc(100% - 80px);
        }
        @include xlarge {
            width: calc(100% - 120px);
            margin-bottom: 40px;
        }
        .single-news-container__content {
            > .wysiwyg {
                width: 100%;
                margin: 0 0 24px;
                @include xlarge {
                    margin-bottom: 40px;
                }
            }
        }
    }
    .single-news-container {
        margin-bottom: 50px;
        @include medium {
            display: flex;
        }
        @include xxlarge {
            margin-bottom: 90px;
        }
        &__image {
            width: 100%;
            height: 167px;
            margin: 0 auto 44px;
            max-width: 375px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            @include medium {
                width: 190px;
                min-width: 190px;
                height: 248px;
                margin: 0 36px 0 0;
            }
            @include xxlarge {
                width: 300px;
                min-width: 300px;
                height: 390px;
                margin-right: 56px;
            }
            &.stock {
                background-size: contain;
                background-color: $pale;
                background-image: url('../images/news-placeholder.svg');
            }
        }
        &__content {
            max-width: 996px;
            h2, h3, h4, h5 {
                font-family: museo-sans, sans-serif;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
            }
            h2, h3, h4, h5 {
                font-size: 1.25rem;
            }
            > ol, > ul {
                margin-bottom: 16px;
                li {
                    margin-bottom: 8px;
                    font-size: 1rem;
                    line-height: 1.33;
                    @include large {
                        font-size: 1.125rem;
                        line-height: 1.5;
                    }
                }
            }
            a {
                color: $cobalt-blue;
                text-decoration: underline;
            }
        }
    }
    .back-to-news {
        margin: 0 auto 50px;
        width: 209px;
        @include xxlarge {
            margin-bottom: 96px;
        }
    }
}
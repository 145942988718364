.quote {
    padding: 38px 28px;
    margin-bottom: 0;
    @include medium {
        padding: 38px 60px 45px 60px;
    }
    @include xxlarge {
        padding: 90px 120px;
    }
    &__outer-container {
        display: flex;
        align-items: flex-start;
        margin: 0 auto;
        @include medium {
            max-width: 737px;
        }
        @include xxlarge {
            max-width: 1000px;
        }
        > svg {
            width: 35px;
            min-width: 35px;
            height: auto;
            margin-right: 16px;
            @include medium {
                width: 50px;
                min-width: 50px;
                margin-right: 28px;
            }
            @include xxlarge {
                width: 70px;
                min-width: 70px;
                margin-right: 53px;
            }
            path {
                fill: $white;
            }
        }
    }
    &__text {
        color: $white;
        font-family: garamond-atf-subhead, serif;
        font-size: 1.375rem;
        font-weight: 400;
        line-height: 1.27;
        font-style: italic;
        @include xxlarge {
            font-size: 1.875rem;
            line-height: 1.33;
        }
    }
    &__author_name {
        font-style: normal;
        margin-left: 6px;
        font-family: garamond-atf-subhead,serif;
        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 2px;
            background-color: $white;
            margin-right: 6px;
            margin-bottom: 6px;
            @include xxlarge {
                margin-bottom: 8px;
                width: 30px;
            }
        }
    }
    &.tan {
        background-color: $pale;
        .quote__outer-container {
            > svg {
                path {
                    fill: $grape-purple;
                }
            }
        }
        .quote__text {
            color: $grape-purple;
        }
        .quote__author_name {
            &::before {
                background-color: $grape-purple;
            }
        }
    }
    &.blue {
        background-color: $cobalt-blue;
    }
    &.red {
        background-color: $grape-purple;
    }
    + .newsletter-form {
        margin-top: -21px;
        @include medium {
            margin-top: -16px;
        }
        @include xlarge {
            margin-top: -21px;
        }
        @include xxlarge {
            margin-top: -40px;
        }
    }
}
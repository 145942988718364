/* Colors */
$white: rgb(255,255,255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$gray-dark: rgb(72, 72, 72); //#484848
$gray: rgb(196, 196, 196); //#c4c4c4
$primary-color: rgb(0, 113, 235); //#0071eb
$secondary-color: rgb(59, 134, 150); //#3b8696
$secondary-color-light: rgb(169, 206, 222);//#a9d4de
$primary-color-dark: rgb(33, 75, 111); // #214b6f
$twilight-blue: rgb(16, 46, 113); // #102e71
$grape-purple:rgb(108, 33, 37); // #6c2125
$beige: rgb(211, 203, 181); // #d3cbb5
$charcoal-grey: rgb(46, 46, 56); // #2e2e38
$cobalt-blue: rgb(0, 12, 153); // #000c99
$night-blue: rgb(14, 1, 76); // #0e014c
$pale: rgb(242, 235, 229); // #f2ebe5
$brown: rgb(204, 153, 51); // #cc9933
$very-light-pink: rgb(255, 250, 242); // #fffaf2
$greyish-brown: rgb(65, 65, 65); // #414141

/* Breakpoint Sizes */
$medium-width: 768px;
$large-width: 1024px;
$xlarge-width: 1250px;
$xxlarge-width: 1600px;
$xxxlarge-width: 1920px;

// Font Weights
$font-weight-norm: 400;
$font-weight-med: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;

// transition speeds
$trans-slow: 0.6s;
$trans-med: 0.4s;
$trans-fast: 0.2s;

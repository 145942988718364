.aside-container {
    background-color: $pale;
    @include medium {
        padding: 40px 40px;
    }
    @include xxlarge {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 50px 0 50px 80px;
    }
    &.blue {
        background-color: $night-blue;
        .aside-container__blocks-container {
            color: $white;
        }
        .full-width, .full-width.blue {
            .full-width__heading, .full-width__button, .full-width__content p {
                color: $white;
            }
            .full-width__button {
                background-image: url('../images/white-arrow.svg');
                background-position: right center;
                background-repeat: no-repeat;
                padding-right: 20px;
                background-size: 16px;
            }
        }
        .full-width.full-width-size {
            .full-width__button {
                color: $white;
            }
        }
        .link-block {
            a {
                span {
                    color: $white;
                }
                svg {
                    path {
                        stroke: $white;
                    }
                }
            }
        }
    }
    &.tan {
        .full-width.blue {
            .full-width__heading, .full-width__button, .full-width__content p {
                color: $black;
            }
        }
    }
    &__blocks-container {
        padding: 28px;
        @include medium {
            padding: 0 0 30px;
            max-width: 930px;
            margin: 0 auto;
        }
        @include xlarge {
            padding-bottom: 40px;
        }
        @include xxlarge {
            margin-right: 125px;
            padding: 0;
            width: 100%;
        }
        @include xxxlarge {
            margin: 0 auto;
        }
        .paragraph.page-width {
            padding: 0;
            @include medium {
                padding: 0;
            }
        }
    }
    &__aside {
        background-color: $cobalt-blue;
        padding: 33px 28px 42px;
        @include medium {
            padding: 52px 41px 60px;
            max-width: 930px;
            margin: 0 auto;
        }
        @include xlarge {
            padding: 52px 120px 60px;
        }
        @include xxlarge {
            max-width: 640px;
            min-height: 630px;
            padding: 110px;
            margin: 0;
        }
    }
    &__heading {
        color: $very-light-pink;
        font-size: 1.625rem;
        line-height: 1.46;
        padding-bottom: 2px;
        border-bottom: 2px solid $brown;
        margin-bottom: 20px;
        display: table;
        @include medium {
            font-size: 2rem;
            line-height: 1.19;
            padding-bottom: 6px;
            margin-bottom: 23px;
        }
        @include xxlarge {
            font-size: 2.8125rem;
            line-height: 0.84;
            padding-bottom: 14px;
            margin-bottom: 32px;
        }
    }
    &__content {
        margin-bottom: 40px;
        @include medium {
            margin-bottom: 28px;
        }
        @include xxlarge {
            margin-bottom: 50px;
        }
        p {
            color: $white;
            font-size: 1rem;
            line-height: 1.5;
        }
    }
    &__button {
        border-color: $white;
        color: $white;
        width: 224px;
        height: 52px;
        background-color: transparent;
        &:hover {
            background-color: $white;
            color: $cobalt-blue;
        }
    }
    .full-width.blue {
        .full-width__heading, .full-width__button, .full-width__content p {
            color: $black;
        }
    }
    .full-width {
        margin-bottom: 30px;
        @include medium {
            margin-bottom: 30px;
        }
        @include xxlarge {
            margin-bottom: 30px;
        }
        &.blue {
            background-color: transparent;
        }
        &.tan {
            background-color: transparent;
        }
        .full-width__image {
            height: 197px;
            width: 100%;
            background-position: center;
            background-size: cover;
            margin-bottom: 18px;
            @include medium {
                width: 260px;
                min-width: 260px;
                height: 160px;
                margin-bottom: 0;
                margin-right: 30px;
            }
            @include xxlarge {
                display: block;
                width: 370px;
                min-width: 370px;
                height: 230px;
                margin-right: 32px;
            }
        }
        .full-width__heading {
            font-size: 1.625rem;
            display: table;
            font-weight: $font-weight-norm;
            font-family: garamond-atf-subhead, serif;
            margin-bottom: 16px;
            letter-spacing: 2px;
            padding-bottom: 0;
            line-height: 1.3;
            border-bottom: 2px solid $brown;
            @include medium {
                font-size: 1.875rem;
                margin-bottom: 20px;
            }
            @include xxlarge {
                font-size: 2.625rem;
                margin-bottom: 24px;
            }
        }
        .full-width__content p {
            font-size: 1rem;
        }
        .full-width__buttons {
            display: block;
        }
        .full-width__button {
            width: auto;
            border: 0;
            height: auto;
            min-height: auto;
            display: table;
            padding: 0;
            color: $black;
            text-transform: none;
            font-size: 1.25rem;
            font-weight: $font-weight-med;
            margin: 0 0 16px;
            background-image: url('../images/arrow-black.svg');
            background-position: right center;
            background-repeat: no-repeat;
            padding-right: 24px;
            background-size: 16px;
            &:last-child {
                margin: 0;
            }
            &:hover {
                background-color: transparent;
                text-decoration: underline;
            }
        }
        .full-width__outer-container {
            display: flex;
            flex-direction: column;
            padding: 30px 0px 0;
            width: 100%;
            max-width: 450px;
            margin: 0 auto;
            @include medium {
                flex-direction: row;
                padding: 30px 0 0;
                max-width: 1010px;
            }
            @include xxlarge {
                max-width: 1600px;
            }
        }
        &.full-width-size {
            padding-top: 30px;
            @include medium {
                padding: 30px 0 0;
            }
            @include xxlarge {
                padding: 30px 0 0;
            }
            .full-width__image {
                display: block;
                height: 197px;
                width: 100%;
                background-position: center;
                background-size: cover;
                margin-bottom: 18px;
                @include medium {
                    width: 260px;
                    min-width: 260px;
                    height: 160px;
                    margin-bottom: 0;
                    margin-right: 30px;
                }
                @include xxlarge {
                    display: block;
                    width: 370px;
                    min-width: 370px;
                    height: 230px;
                    margin-right: 30px;
                }
            }
            .full-width__heading {
                font-size: 1.625rem;
                display: table;
                text-transform: none;
                font-weight: $font-weight-norm;
                font-family: garamond-atf-subhead, serif;
                margin-bottom: 16px;
                letter-spacing: 2px;
                padding-bottom: 0;
                line-height: 1.3;
                border-bottom: 2px solid $brown;
                @include medium {
                    font-size: 1.875rem;
                    margin-bottom: 20px;
                }
                @include xxlarge {
                    font-size: 2.625rem;
                    margin-bottom: 24px;
                }
            }
            .full-width__content p {
                font-size: 1rem;
            }
            .full-width__buttons {
                display: block;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin-top: 32px;
                padding-bottom: 10px;
                @include medium {
                    margin-top: 24px;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }
                @include xxlarge {
                    margin-top: 40px;
                }
            }
            .full-width__outer-container {
                padding: 0;
                @include medium {
                    padding: 0;
                }
            }
            .full-width__button {
                width: auto;
                border: 0;
                height: auto;
                display: table;
                padding: 0;
                color: $black;
                text-transform: none;
                font-size: 1.25rem;
                font-weight: $font-weight-med;
                margin: 0 0 16px;
                padding-right: 20px;
                &:last-child {
                    margin: 0;
                }
                &:hover {
                    background-color: transparent;
                    text-decoration: underline;
                }
            }
            &.right {
                .full-width__image {
                    @include medium {
                        margin-right: 0;
                        margin-left: 30px;
                    }
                    @include xxlarge {
                        margin-right: 0;
                        margin-left: 30px;
                    }
                }
            }
        }
        &.right {
            .full-width__image {
                @include medium {
                    margin-right: 0;
                    margin-left: 30px;
                }
                @include xxlarge {
                    margin-right: 0;
                    margin-left: 30px;
                }
            }
        }
    }
}
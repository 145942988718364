.dual-event {
    display: flex;
    align-items: flex-start;
    padding: 0 6px;
    max-width: 450px;
    width: 100%;
    flex: 1;
    margin: 0 auto 56px;
    @include medium {
        max-width: 680px;
    }
    @include xxlarge {
        max-width: none;
    }
    &__event-date {
        margin-right: 22px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include medium {
            margin-right: 45px;
        }
    }
    &__event-month {
        font-size: 1.25rem;
        font-family: garamond-atf-subhead, serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1.45;
        color: #1c1c1c;
        margin-bottom: 5px;
        padding: 5px 2px 0;
        border-top: 4px solid $grape-purple;
        text-align: right;
        transition: color $trans-med ease;
        @include medium {
            font-size: 2.125rem;
            letter-spacing: 3.4px;
        }
    }
    &__event-day {
        font-size: 5.1875rem;
        font-weight: $font-weight-med;
        font-style: italic;
        font-family: garamond-atf-subhead, serif;
        color: $grape-purple;
        line-height: .7;
        @include medium {
            font-size: 8.0625rem;
        }
    }
    &__container {
        margin-top: 10px;
        @include medium {
            margin-top: 14px;
        }
    }
    &__event-title {
        font-size: 1.375rem;
        line-height: 1.27;
        margin-bottom: 12px;
        color: $charcoal-grey;
        transition: color $trans-med ease;
        @include medium {
            font-size: 2rem;
        }
    }
    &__event-description {
        color: $charcoal-grey;
        font-size: .875rem;
        line-height: 1.43;
        @include medium {
            font-size: 1rem;
            line-height: 1.5;
        }
    }
    &:hover {
        .dual-event__event-title {
            color: $grape-purple;
        }
        .dual-event__event-month {
            color: $grape-purple;
        }
    }
}
.floating-card {
    border-top: 14px solid $cobalt-blue;
    border-radius: 4px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: $white;
    padding: 20px 40px 75px;
    min-height: 268px;
    margin-top: 0;
    margin: 0 auto 25px;
    max-width: 450px;
    width: 100%;
    box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.16);
    transition: border-color $trans-med ease;
    @include medium {
        padding-top: 32px;
        max-width: 720px;
    }
    @include xxlarge {
        flex: 1;
        min-width: 46%;
        margin-bottom: 80px;
    }
    &:nth-child(even) {
        @include xxlarge {
            margin-left: 80px;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
    &:nth-last-child(-n+2) {
        @include xxlarge {
            margin-bottom: 0;
        }
    }
    &__heading {
        font-family: garamond-atf-subhead, serif;
        color: $cobalt-blue;
        font-size: 1.25rem;
        font-weight: $font-weight-med;
        line-height: 1;
        margin-bottom: 14px;
        transition: color $trans-med ease;
        @include medium {
            font-size: 1.75rem;
        }
    }
    &__content {
        margin-bottom: 20px;
        color: $greyish-brown;
        p {
            font-size: .875rem;
            font-weight: 300;
            line-height: 1.29;
            color: $greyish-brown;
            @include medium {
                font-size: 1rem;
                line-height: 1.5;
                margin-bottom: 30px;
            }
        }
        ul, ol {
            padding-left: 16px;
            margin-bottom: 20px;
            li {
                margin-bottom: 8px;
            }
        }
    }
    &__link {
        font-size: 1.25rem;
        position: absolute;
        bottom: 40px;
        left: 40px;
        color: $cobalt-blue;
        font-weight: $font-weight-med;
        transition: color $trans-med ease;
        svg {
            margin-left: 12px;
            path {
                transition: stroke $trans-med ease;
            }
        }
    }
}

a.floating-card {
    &:hover {
        border-color: $night-blue;
        .floating-card__heading, .floating-card__link {
            color: $night-blue;
        }
        .floating-card__link {
            svg {
                path {
                    stroke: $night-blue;
                }
            }
        }
    }
}
header {
    //
}
.header-container {
    height: 89px;
    border-top: 5px solid $cobalt-blue;
    background-color: $white;
    padding: 0 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include medium {
        padding: 0 40px;
        height: 101px;
    }
    @include xxlarge {
        height: 165px;
        padding: 0 140px 0 60px;
    }
    .site-name {
        display: none;
        @include medium {
            display: block;
            color: $cobalt-blue;
            font-size: 1.25rem;
            font-family: garamond-atf-subhead, serif;
            margin-right: auto;
        }
        @include xxlarge {
            font-size: 2.125rem;
        }
    }
    .logo {
        display: block;
        width: 68px;
        height: 68px;
        @include medium {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        @include xxlarge {
            width: 126px;
            height: 126px;
        }
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .header-buttons {
        display: none;
        @include medium {
            display: flex;
            margin-left: auto;
            align-items: center;
            margin-right: 42px;
        }
        .search-toggle {
            font-size: 1.125rem;
            color: $greyish-brown;
            margin-right: 6px;
            background-image: url('../images/search-icon.svg');
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 15px;
            padding: 2px 24px 2px 0;
            @include xlarge {
                margin-right: 36px;
            }
            @include xxlarge {
                font-size: 1.25rem;
                background-size: 16px;
            }
            &:hover {
                color: $cobalt-blue;
                font-weight: $font-weight-bold;
            }
            &.active {
                color: $cobalt-blue;
                font-weight: $font-weight-bold;
                border-bottom: 2px solid $brown;
            }
        }
        .support-button {
            width: 209px;
            display: none;
            @include xlarge {
                display: flex;
            }
        }
    }
    .menu-toggle {
        z-index: 2;
        transition: margin-top $trans-med ease, padding-top $trans-med ease; 
        padding-top: 8px;
        span {
            background-color: $cobalt-blue;
            width: 37px;
            height: 7px;
            margin-bottom: 7px;
            display: block;
            opacity: 1;
            transition: transform $trans-med ease, opacity $trans-fast ease, height $trans-med ease;
        }
        &.active {
            padding-top: 0;
            span {
                transform: rotate(45deg);
                margin-top: 10px;
                height: 5px;
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child {
                    transform: rotate(135deg);
                    margin-top: -27px;
                }
            }
        }
    }
}

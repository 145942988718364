.library-search {
    background-color: $pale;
    padding: 32px 42px;
    text-align: center;
    margin-bottom: 80px;
    @include medium {
        max-width: 1520px;
        margin: 0 auto 80px;
    }
    @include xxlarge {
        padding: 60px 40px;
    }
    &__heading {
        font-size: 1.25rem;
        font-weight: $font-weight-bold;
        color: $charcoal-grey;
        line-height: 1.5;
        margin-bottom: 36px;
        font-family: museo-sans, sans-serif;
        @include xxlarge {
            font-size: 1.5rem;
            margin-bottom: 62px;
        }
    }
    &__tabs-container {
        @include xxlarge {
            display: flex;
            justify-content: center;
            margin-bottom: 36px;
            flex-wrap: wrap;
        }
    }
    &__tabs-label {
        color: $grape-purple;
        font-weight: $font-weight-bold;
        display: block;
        text-transform: uppercase;
        line-height: 1.2;
        letter-spacing: 3px;
        font-size: 1.25rem;
        font-family: museo-sans, sans-serif;
        margin-bottom: 24px;
        @include xxlarge {
            width: 200px;
            text-align: center;
        }
    }
    &__tabs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 28px;
        @include medium {
            flex-direction: row;
            flex-wrap: wrap;
            max-width: 900px;
            margin: 0 auto 28px;
        }
        @include xxlarge {
            flex-wrap: nowrap;
            max-width: none;
            margin: 0;
        }
    }
    &__tab {
        width: 210px;
        border-color: $grape-purple;
        color: $grape-purple;
        margin-bottom: 24px;
        background-color: transparent;
        cursor: pointer;
        @include medium {
            margin: 0 14px 28px;
        }
        &:hover {
            background-color: $grape-purple;
            color: $white;
        }
        &.active {
            background-color: $grape-purple;
            color: $white;
        }
    }
    &__tab-contents {

    }
    &__tab-content {
        display: none;
        &.active {
            display: block;
        }
        label {
            margin-bottom: 8px;
            display: block;
        }
        input[type="text"] {
            border: 3px solid $brown;
            font-size: 1rem;
            border-radius: 5px;
            background-color: $white;
            width: 100%;
            height: 52px;
            max-width: 320px;
            margin: 0 auto 26px;
            padding: 6px 12px;
            @include medium {
                height: 48px;
                max-width: 527px;
                margin: 0 18px 0 0;
            }
            @include xxlarge {
                max-width: 631px;
            }
        }
        .library-search-info-toggle {
            color: $cobalt-blue;
            font-size: 1.125rem;
            font-weight: $font-weight-semi;
            display: table;
            margin: 0 auto 24px;
            @include medium {
                font-size: 1.25rem;
                margin-bottom: 30px;
            }
            svg {
                margin-left: 8px;
                width: 16px;
                height: auto;
                transition: transform $trans-med ease;
                @include medium {
                    width: 18px;
                }
            }
            &.active {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
        .moreinfo {
            max-height: 0;
            overflow: hidden;
            transition: max-height $trans-med ease;
            &.active {
                max-height: 2000px;
            }
        }
        .advanced-search-link {
            color: $cobalt-blue;
            font-size: 1.25rem;
            font-weight: $font-weight-med;
            svg {
                margin-left: 7px;
            }
        }
        .btn {
            width: 210px;
            min-width: 210px;
            background-color: transparent;
            cursor: pointer;
            &:hover {
                color: $white;
                background-color: $cobalt-blue;
            }
        }
        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 38px;
            @include medium {
                flex-direction: row;
                justify-content: center;
            }
        }
        .db-list {
            @include medium {
                display: flex;
                gap: 0 50px;
                justify-content: center;
            }
            .db-list-left, .db-list-center, .db-list-right {
                text-align: center;
                @include medium {
                    text-align: left;
                }
                > h4 {
                    font-family: museo-sans, sans-serif;
                    font-size: 1.375rem;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    letter-spacing: 3.3px;
                    text-align: center;
                    @include medium {
                        text-align: left;
                    }
                    @include xxlarge {
                        font-size: 1.875rem;
                        margin-bottom: 14px;
                    }
                }
                ul {
                    margin-bottom: 22px;
                    li {
                        display: flex;
                        justify-content: center;
                        font-size: .875rem;
                        margin-bottom: 10px;
                        @include medium {
                            justify-content: flex-start;
                        }
                        @include xxlarge {
                            font-size: 1rem;
                        }
                        &::before {
                            content: '';
                            width: 9px;
                            height: 9px;
                            background-color: $twilight-blue;
                            border-radius: 50%;
                            display: inline-block;
                            margin-right: 8px;
                            margin-top: 3px;
                        }
                    }
                }
            }
        }
        #ebscohostCustomSearchBox {
            @include medium {
                flex-direction: column;
            }
        }
        .ebsco-single-search {
            @include medium {
                flex-direction: column;
            }
            @include large {
                flex-direction: row;
            }
            select {
                border: 3px solid $brown;
                border-radius: 5px;
                background-color: $white;
                font-size: 1rem;
                width: 100%;
                height: 48px;
                max-width: 210px;
                min-width: 210px;
                margin: 0 auto 26px;
                padding: 6px 22px;
                @include large {
                    margin: 0 18px 0 0;
                }
            }
            input[type="text"] {
                margin: 0 auto 12px;
                @include large {
                    margin: 0 18px 0 0;
                }
            }
        }
        .select-search-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 32px;
            width: 100%;
            @include medium {
                flex-direction: row;
            }
            #ebschohostFields {
                input[type="text"] {
                    min-width: 200px !important;
                }
            }
            > span {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                @include medium {
                    display: flex;
                    flex-direction: row;
                    width: 77%;
                    max-width: 630px;
                }
                @include xxlarge {
                    max-width: 860px;
                }
            }
            select {
                border: 3px solid $brown;
                border-radius: 5px;
                background-color: $white;
                font-size: 1rem;
                width: 100%;
                height: 48px;
                max-width: 210px;
                min-width: 210px;
                margin: 0 auto 26px;
                padding: 6px 22px;
                @include medium {
                    margin: 0 18px 0 0;
                }
            }
            input[type="text"] {
                font-size: 1rem;
                border: 3px solid $brown;
                border-radius: 5px;
                background-color: $white;
                width: 100%;
                height: 48px;
                max-width: 320px;
                margin: 0 auto 26px;
                padding: 6px 12px;
                @include medium {
                    max-width: none;
                    margin: 0 18px 0 0;
                }
            }
        }
        .library-select-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include medium {
                flex-direction: row;
                flex-wrap: wrap;
                gap: 24px;
            }
            > span {
                margin: 0 auto 26px;
                @include medium {
                    margin: 0 18px 0 0;
                }
                &:last-child {
                    @include medium {
                        margin: 0;
                    }
                }
                select {
                    border: 3px solid $brown;
                    border-radius: 5px;
                    background-color: $white;
                    font-size: 1rem;
                    width: 100%;
                    height: 48px;
                    max-width: 210px;
                    min-width: 210px;
                    padding: 6px 22px;
                    margin-bottom: 12px;
                    @include medium {
                        margin: 0 18px 0 0;
                    }
                }
            }
            .dbFields {
                display: flex;
                flex-direction: column;
                @include medium {
                    flex-direction: row;
                    margin: 0;
                }
            }
        }
        .library-search__link-container {
            max-width: 315px;
            margin: 0 auto;
            text-align: left;
            @include medium {
                max-width: 900px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            @include xxlarge {
                max-width: 1065px;
            }
            a {
                display: block;
                margin-bottom: 24px;
                @include medium {
                    flex-basis: 45%;
                    margin-bottom: 16px;
                    text-align: left;
                }
                @include xxlarge {
                    flex-basis: 48%;
                }
                &:nth-child(even) {
                    @include medium {
                        margin-left: 10%;
                    }
                    @include xxlarge {
                        margin-left: 4%;
                    }
                }
                span {
                    color: $cobalt-blue;
                    font-size: 1rem;
                    font-weight: 500;
                    display: inline;
                    letter-spacing: 0.8px;
                    @include medium {
                        font-size: 1.25rem;
                        letter-spacing: 1px;
                    }
                }
                svg {
                    margin-left: 6px;
                    margin-top: 6px;
                    display: inline;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .library-search__link-container-single {
            max-width: 315px;
            margin: 0 auto;
            text-align: left;
            @include medium {
                max-width: 900px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            @include xxlarge {
                max-width: 1065px;
            }
        }
        .library-single-links {
            display: block;
            margin-bottom: 24px;
            span {
                color: $cobalt-blue;
                font-size: 1rem;
                font-weight: 500;
                display: inline;
                letter-spacing: 0.8px;
                @include medium {
                    font-size: 1.25rem;
                    letter-spacing: 1px;
                }
            }
            svg {
                margin-left: 6px;
                margin-top: 6px;
                display: inline;
            }
            &:hover {
                text-decoration: underline;
            }
        }
        ::-webkit-input-placeholder {
            font-style: italic;
        }
        :-moz-placeholder {
            font-style: italic;
        }
        ::-moz-placeholder {
            font-style: italic;
        }
        :-ms-input-placeholder {
            font-style: italic;
        }
        ::placeholder {
            font-style: italic;
        }
    }
}

#ebschohostFields, #jstorFields, #proquestFields, #oclcFields {
    display: none;

    &.active {
        display: inherit;
    }
}

.footer {
    padding: 27px 40px 40px;
    background-color: $night-blue;
    @include medium {
        padding: 40px 54px 54px;
    }
    @include xlarge {
        padding: 75px 160px 70px;
    }
    &__container {
        max-width: 400px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        @include medium {
            flex-wrap: nowrap;
            max-width: 1520px;
        }
    }
    &__logo {
        flex-basis: 20%;
        max-width: 78px;
        @include medium {
            flex-basis: auto;
            min-width: 102px;
            width: 102px;
            margin-right: 50px;
        }
        @include xxlarge {
            min-width: 129px;
            width: 129px;
            margin-right: 60px;
        }
        svg {
            width: 100%;
            height: auto;
        }
    }
    &__content-container {
        order: 2;
        flex-basis: 100%;
        @include medium {
            flex-basis: auto;
            padding-right: 7%;
            max-width: 500px;
        }
        @include xlarge {
            padding-right: 0;
            max-width: 430px;
        }
    }
    &__summary {
        display: none;
        @include medium {
            display: block;
            color: $white;
            font-size: .875rem;
            line-height: 1.43;
            margin-bottom: 32px;
        }
        @include xxlarge {
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 60px;
        }
    }
    &__copyright {
        color: $white;
        font-size: .875rem;
        line-height: 1.43;
    }
    &__section {
        flex-basis: 80%;
        padding-left: 40px;
        margin-bottom: 32px;
        margin-left: auto;
        max-width: 275px;
        @include medium {
            flex-basis: auto;
            order: 3;
            margin-left: auto;
            min-width: 250px;
        }
        @include xxlarge {
            min-width: 285px;
        }
    }
    &__contact-heading {
        font-size: 1.75rem;
        color: $white;
        margin-bottom: 15px;
        @include xxlarge {
            font-size: 2.125rem;
        }
    }
    &__contact-information {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        max-width: 215px;
        @include xxlarge {
            max-width: 250px;
            margin-bottom: 20px;
        }
    }
    &__contact-address, &__contact-phone, &__contact-email {
        color: $white;
        font-size: .875rem;
        line-height: 1.43;
        margin-bottom: 4px;
        @include xxlarge {
            font-size: 1rem;
        }
    }
    &__contact-phone, &__contact-email {
        &:hover {
            text-decoration: underline;
        }
    }
    &__social-links {
        display: flex;
    }
    &__social-link {
        background-color: $white;
        height: 36px;
        width: 36px;
        min-width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        border-radius: 50%;
        transition: background-color $trans-med ease;
        svg {
            width: 100%;
            height: 100%;
        }
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            background-color: $cobalt-blue;
        }
    }
}
.background {
    background-position: center 35%;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 32px 0;
    @include medium {
        padding: 40px 0;
    }
    @include xlarge {
        padding: 50px 0;
    }
    .background__inner-container {
        margin-bottom: 0;
        @include medium {
            margin-bottom: 0;
        }
        @include xlarge {
            margin-bottom: 0;
        }
        .full-width {
            margin-bottom: 0;
            &.full-width-size {
                @include medium {
                    padding: 32px 0;
                }
                @include xxlarge {
                    padding: 36px 0;
                }
            }
        }
        .row {
            padding: 30px 0;
        }
    }
    &.page-width {
        padding: 32px 16px;
        @include medium {
            padding: 40px 24px;
        }
        @include xlarge {
            padding: 50px 40px;
        }
    }
    &.full-width {
        margin: 0;
    }
}
.single-staff {
    .hero__content {
        text-align: center;
        padding: 20px 16px;
        @include medium {
            max-width: 1250px;
            padding: 26px 40px;
        }
        @include xxlarge {
            max-width: 1640px;
            width: 90%;
            left: auto;
            transform: none;
            position: relative;
            padding: 58px 60px;
        }
    }
    .staff-details {
        display: flex;
        flex-direction: column;
        @include medium {
            flex-direction: row;
        }
        &__image {
            width: 207px;
            height: 270px;
            margin: 0 auto 56px;
            @include medium {
                width: 300px;
                height: 390px;
                min-width: 300px;
                margin: 0 42px 0 0;
            }
            .i72-image {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
            }
        }
        &__content {
            text-align: left;
        }
        &__title {
            font-size: 1.375rem;
            color: $gray-dark;
            line-height: 1.09;
            margin-bottom: 20px;
            @include xlarge {
                font-size: 1.875rem;
                margin-bottom: 12px;
            }
        }
        &__jobs {
            p {
                font-size: .875rem;
                line-height: 1.43;
                font-style: italic;
                font-weight: $font-weight-semi;
                color: $gray-dark;
                @include xlarge {
                    font-size: 1rem;
                    line-height: 1.5;
                }
            }
        }
        &__education {
            p {
                font-size: .875rem;
                line-height: 1.43;
                color: $gray-dark;
                @include xlarge {
                    font-size: 1rem;
                    line-height: 1.5;
                }
            }
        }
        &__contact-info {
            display: flex;
            flex-direction: column;
            margin-bottom: 22px;
            a {
                display: table;
                color: $cobalt-blue;
                font-size: .875rem;
                font-weight: $font-weight-semi;
                text-decoration: underline;
                margin-bottom: 10px;
                @include xlarge {
                    font-size: 1rem;
                    line-height: 1.5;
                }
            }
        }
    }
    .wysiwyg {
        h1, h2, h3, h4, h5, h6 {
            font-family: museo-sans,sans-serif;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;
            @include medium {
                letter-spacing: 3px;
            }
            @include xlarge {
                letter-spacing: 3.5px;
            }
        }
        ul {
            li {
                list-style: none;
                &::before {
                    content: '';
                    background-color: $twilight-blue;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    position: absolute;
                    top: 5px;
                    left: -16px;
                }
            }
        }
    }
}
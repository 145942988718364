.carousel.page-width {
   margin: 0 auto 48px;
   width: 100vw;
   @include medium {
       margin-bottom: 60px;
       width: 100vw;
   } 
   @include xlarge {
       margin-bottom: 80px;
       width: calc(100% - 120px);
   }
   > h2 {
    text-align: center;
    margin-bottom: 24px;
    @include medium {
        margin-bottom: 32px;
    }
}
}
.carousel__container {
    overflow-x: scroll;
    padding: 0 12px 18px;
    margin: 0 auto;
    max-width: 100%;
    @include medium {
        padding: 0 18px 24px;
    }
    @include xlarge {
        overflow-x: hidden;
        padding: 0;
        max-width: 1060px;
    }
    @include xxlarge {
        max-width: 1230px;
    }
}
.carousel__current-slide {
    display: none;
    @include xlarge {
        display: block;
        width: 844px;
        height: 450px;
        border-radius: 8px;
        overflow: hidden;
        margin: 0 auto 16px;
    }
    @include xxlarge {
        width: 1018px;
        height: 572px;
        margin-bottom: 24px;
    }
    .carousel__img {
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
    }
}
.carousel__slides-container {
    @include xlarge {
        max-width: 882px;
        margin: 0 auto;
        overflow-x: hidden;
    }
    @include xxlarge {
        max-width: 1068px;
    }
}
.carousel__slides {
    display: flex;
    justify-content: flex-start;
    > li {
        display: block;
        list-style: none;
        height: 120px;
        width: 210px;
        min-width: 210px;
        margin: 16px;
        overflow: hidden;
        border-radius: 8px;
        @include medium {
            width: 310px;
            min-width: 310px;
            height: 174px;
            margin: 16px 22px;
        }
        @include xlarge {
            width: 251px;
            min-width: 251px;
            height: 145px;
        }
        @include xxlarge {
            width: 311px;
            min-width: 311px;
            height: 174px;
        }
        .carousel__img {
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
        }
    }
}

.carousel__prev, .carousel__next {
    position: absolute;
    bottom: 80px;
    left: 0;
    z-index: 3;
    svg {
        transform: rotate(90deg);
        display: none;
        transition: background-color $trans-med ease;
        @include xlarge {
            display: block;
            width: 44px;
            height: 44px;
            padding: 13px;
            border: 1px solid $cobalt-blue;
            border-radius: 5px;
        }
        path {
            transition: fill $trans-med ease;
        }
    }
    &:hover {
        svg {
            background-color: $cobalt-blue;
            path {
                fill: $white
            }
        }
    }
}

.carousel__next {
    left: auto;
    right: 0;
    z-index: 3;
    svg {
        transform: rotate(270deg);
    }
}
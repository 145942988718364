.hero {
    min-height: 368px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: $cobalt-blue;
    @include medium {
        min-height: 411px;
    }
    @include xxlarge {
        min-height: 432px;
    }
    &.no-image {
        min-height: auto;
        height: 95px;
        @include medium {
            min-height: auto;
        }
        @include xxlarge {
            min-height: auto;
        }
    }
    &.blue {
        background-color: $cobalt-blue;
        .hero__color-strip {
            background-color: $cobalt-blue;
        }
    }
    &.blue-dark {
        background-color: $night-blue;
        .hero__color-strip {
            background-color: $night-blue;
        }
    }
    &.yellow {
        background-color: $brown;
        .hero__color-strip {
            background-color: $brown;
        }
    }
    &.red {
        background-color: $grape-purple;
        .hero__color-strip {
            background-color: $grape-purple;
        }
    }
    &__color-strip {
        height: 80px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -40px;
        background-color: $cobalt-blue;
        @include medium {
            bottom: 0;
            height: 24px;
        }
    }
    &__content {
        padding: 32px 16px 20px;
        width: 94%;
        margin: 0 auto;
        z-index: 2;
        text-align: center;
        background-color: $white;
        @include medium {
            padding: 24px 116px 48px;
            max-width: 930px;
            width: 90%;
        }
        @include xxlarge {
            padding: 38px 52px;
            max-width: 1120px;
            width: 65%;
            text-align: left;
            left: calc(50% - 252px);
            transform: translateX(-50%);
            position: absolute;
        }
    }
    &__title {
        margin-bottom: 18px;
        color: $black;
        font-size: 1.625rem;
        line-height: 1.23;
        @include medium {
            font-size: 2.25rem;
            margin-bottom: 14px;
        }
        @include xxlarge {
            font-size: 3.5rem;
            margin-bottom: 16px;
        }
    }
    &__excerpt {
        color: $black;
        font-size: .875rem;
        line-height: 1.43;
        @include medium {
            font-size: .875rem;
        }
        @include xxlarge {
            font-size: 1rem;
            line-height: 1.5;
        }
    }
    &__button {
        //
    }
}
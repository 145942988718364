.svg-icon-text-block {
    display: flex;
    align-items: flex-start;
    flex: 1;
    padding: 0 6px;
    max-width: 500px;
    margin: 0 auto 36px;
    @include medium {
        max-width: 742px;
        margin-bottom: 90px;
    }
    &__icon {
        width: 31px;
        min-width: 31px;
        margin-right: 18px;
        margin-top: 4px;
        @include medium {
            margin-right: 42px;
            width: 44px;
            min-width: 44px;
        }
        .i72-svg {
            width: 100%;
            height: 100%;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__text-section {
        > h3 {
            font-size: 1.5rem;
            color: $grape-purple;
            margin-bottom: 6px;
            @include medium {
                font-size: 2.25rem;
            }
        }
    }
    &__content {
        margin-bottom: 24px;
        @include medium {
            margin-bottom: 36px;
        }
        p, ul, ol {
            color: $grape-purple;
            font-size: 1rem;
            line-height: 1.25;
            margin-bottom: 16px;
            @include medium {
                line-height: 1.5;
            }
        }
        ol, ul {
            padding-left: 16px;
            li {
                margin-bottom: 6px; 
            }
        }
        h1, h2, h3, h4, h5, h6 {
            color: $grape-purple;
        }
        a {
           color: $cobalt-blue;
           text-decoration: underline;
        }
    }
    &__link {
        display: inline-block;
        color: $cobalt-blue;
        font-weight: $font-weight-med;;
        @include xxlarge {
            font-size: 1.25rem;
        }
        svg {
            width: 20px;
            margin-left: 3px;
            top: 1px;
            @include xxlarge {
                width: 26px;
                margin-left: 5px;
                top: auto;
            }
        }
        &:hover {
            text-decoration: underline;
        }
    }
}
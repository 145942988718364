.home-hero {
    &__image {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        height: 192px;
        width: 100%;
        @include medium {
            height: 427px;
        }
        @include xxlarge {
            height: 635px;
        }
        .overlay {
            background-image: linear-gradient(to top, rgba(14,1,76,.5), rgba(14,1,76,0.33), rgba(14,1,76,0));
            position: absolute;
            inset: 0;
        }
    }
    &__overlay {
        background-image: linear-gradient(to bottom, rgba(14, 1, 76, 0), rgb(14, 2, 76) 110px, #0e034c 67%);
        position: absolute;
        top: 85px;
        width: 100%;
        height: 100%;
        @include medium {
            top: 267px;
        }
        @include xxlarge {
            top: 460px;
        }
    }
    &__heading {
        font-size: 1.75rem;
        font-style: italic;
        color: $white;
        margin-bottom: 8px;
        padding: 115px 36px 0;
        @include medium {
            font-size: 3.5rem;
            line-height: 1.43;
            padding: 200px 40px 0 80px;
            max-width: 1525px;
            margin: 0 auto 12px;
        }
        @include xxlarge {
            font-size: 4.5rem;
            line-height: 1.11;
            padding-top: 280px;
        }
    }
    &__content {
        padding: 0 36px 36px;
        @include medium {
            padding: 0 40px 60px 80px;
            max-width: 1525px;
            margin: 0 auto;
        }
        @include xlarge {
            padding-bottom: 72px;
            padding-right: 80px;
        }
        @include xxlarge {
            padding-bottom: 124px;
        }
        p {
            color: $white;
            line-height: 1.25;
            @include medium {
                font-size: 1.125rem;
                font-weight: $font-weight-med;
                line-height: 1.39;
                margin-bottom: 28px;
                max-width: 950px;
            }
            @include xxlarge {
                font-size: 1.25rem;
                line-height: 1.5;
                margin-bottom: 40px;
            }
        }
        > .home-hero__accordions {
            margin-top: 35px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            @include medium {
                flex-direction: row;
                margin-top: 58px;
                justify-content: space-between;
                gap: 3vw;
            }
            @include xlarge {
                gap: 32px;
            }
            @include xxlarge {
                margin-top: 80px;
            }
            .home-hero__accordion-button {
                border: 2px solid $white;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 19px 54px 10px 24px;
                min-height: 93px;
                border-radius: 5px;
                font-size: 1rem;
                text-transform: uppercase;
                line-height: 1.25;
                letter-spacing: 1.6px;
                color: $white;
                max-width: 325px;
                font-weight: $font-weight-bold;
                cursor: pointer;
                @include medium {
                    min-height: 134px;
                    max-width: none;
                    min-width: auto;
                    margin-right: 0;
                    margin-bottom: 0;
                    flex: 1;
                }
                @include xlarge {
                    min-height: 136px;
                    padding: 18px 58px 14px 31px;
                    font-size: 1.375rem;
                    line-height: 1.45;
                    letter-spacing: 2.2px;
                }
                @include xxlarge {
                    min-height: 158px;
                    padding: 29px 60px 18px 39px;
                }
                &.hide {
                    display: none;
                }
                &.btn {
                    margin-right: 0;
                }
                &:hover {
                    span {
                        background-color: $white;
                        svg {
                            path {
                                fill: $cobalt-blue;
                            }
                        }
                    }
                }
                span {
                    background-color: $cobalt-blue;
                    border-radius: 2px;
                    width: 34px;
                    min-width: 34px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 5px;
                    bottom: 5px;
                    transition: background-color $trans-med ease;
                    @include medium {
                        width: 42px;
                        min-width: 42px;
                        height: 42px;
                    }
                    @include xxlarge {
                        width: 44px;
                        min-width: 44px;
                        height: 44px;
                    }
                    svg {
                        width: 12px;
                        height: auto;
                        @include medium {
                            width: 14px;
                        }
                        @include xxlarge {
                            width: 16px;
                        }
                        path {
                            transition: fill $trans-med ease;
                        }
                    }
                }
            }
        }
        .home-hero_navigation {
            display: flex;
            flex-direction: column;
            gap: 14px;
            margin-top: 24px;
            @include medium {
                flex-direction: row;
                gap: 3vw;
                justify-content: space-around;
            }
            @include xlarge {
                gap: 32px;
            }
            .home-hero__nav-button {
                border-color: $white;
                color: $white;
                border-width: 2px;
                width: auto;
                max-width: 280px;
                flex: 1;
                @include medium {
                    max-width: 294px;
                }
                &:hover {
                    background-color: $white;
                    color: $cobalt-blue;
                }
            }
        }
    }
    &__button {
        border-color: $white;
        background-color: $white;
        font-size: .875rem;
        padding: 6px 8px;
        min-height: 39px;
        width: 224px;
        @include medium {
            min-height: 45px;
            font-size: 1rem;
            padding: 8px 12px;
            width: 258px;
        }
        &:hover {
            background-color: $night-blue;
        }
    }
    &__accordion-content-section {
        display: none;
        &.active {
            display: flex;
            flex-direction: column;
            @include medium {
                flex-direction: row;
            }
        }
        .home-hero__left-container {
            @include medium {
                margin-right: 32px;
            }
            @include large {
                margin-right: 75px;
            }
            @include xlarge {
                margin-right: 160px;
            }
            @include xxlarge {
                margin-right: 225px;
            }
            > h2 {
                font-family: museo-sans, sans-serif;
                font-size: 1.9375rem;
                font-weight: $font-weight-bold;
                letter-spacing: 3.1px;
                text-transform: uppercase;
                color: $white;
                margin-bottom: 18px;
                display: flex;
                align-items: flex-start;
                @include medium {
                    font-size: 2.8125rem;
                    letter-spacing: 4.5px;
                    margin-left: -59px;
                }
                @include xxlarge {
                    font-size: 3.75rem;
                    letter-spacing: 6px;
                    margin-bottom: 50px;
                    margin-left: -73px;
                }
                a {
                    background-color: $cobalt-blue;
                    border-radius: 2px;
                    width: 34px;
                    min-width: 34px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 11px;
                    margin-top: 2px;
                    transition: background-color $trans-med ease;
                    @include medium {
                        width: 42px;
                        min-width: 42px;
                        height: 42px;
                        margin-top: 6px;
                        margin-right: 17px;
                    }
                    @include xxlarge {
                        width: 44px;
                        min-width: 44px;
                        height: 44px;
                        margin-right: 29px;
                        margin-top: 12px;
                    }
                    svg {
                        width: 12px;
                        height: auto;
                        @include medium {
                            width: 14px;
                        }
                        @include xxlarge {
                            width: 16px;
                        }
                        path {
                            transition: fill $trans-med ease;
                        }
                    }
                    &:hover {
                        background-color: $white;
                        svg {
                            path {
                                fill: $cobalt-blue;
                            }
                        }
                    }
                }
            }
            > .home-hero__accordion-content {
                p {
                    font-size: 1rem;
                    line-height: 1.5;
                    margin-bottom: 16px;
                    font-weight: 300;
                    @include xlarge {
                        margin-bottom: 20px;
                    }
                    @include xxlarge {
                        margin-bottom: 24px;
                    }
                }
            }
            > .home-hero__accordion-cta-button {
                border-color: $cobalt-blue;
                background-color: $cobalt-blue;
                color: $white;
                font-size: .875rem;
                width: 261px;
                min-height: 48px;
                margin: 0 auto 18px;
                @include medium {
                    width: 200px;
                    margin: 0 0 16px;
                    font-size: 1rem;
                }
                &:hover {
                    border-color: $white;
                    background-color: $white;
                    color: $night-blue;
                }
            }
        }
        .home-hero__accordion-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include medium {
                margin-top: 60px;
                align-items: flex-end;
            }
            @include xxlarge {
                margin-top: 115px;
            }
            .home-hero__accordion-button {
                color: $white;
                font-size: .875rem;
                width: 261px;
                min-width: 261px;
                min-height: 48px;
                background-color: transparent;
                border-color: $white;
                margin-bottom: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 6px;
                cursor: pointer;
                flex: none;
                @include xxlarge {
                    min-width: 319px;
                    font-size: 1rem;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                &:hover {
                    border-color: $cobalt-blue;
                    background-color: $cobalt-blue;
                    color: $white;
                }
            }
        }
    }
}